import React, { useEffect } from "react";
import { useFormik } from "formik";
import { addRatings } from "../../Api/api";
import { getRatingsByProduct } from "../../Api/api";
import {
  ratingsMockData,
  somethingWentWrong,
  statusSuccess,
} from "../../global/constants";
import { rating_form } from "../../schemas/Forms";
import { ratings_validation } from "../../schemas/Validations";
import { useGlobalProvider } from "../../global/provider";
import { useAuth } from "../../contexts/auth";
import { isValidArrayWithData, isValidValue } from "../../utils/reUsableFun";
import "./reviewAndRatings.css";
import { makePostRequest, makeGetRequest } from "../../Api/requestMethods";
import { formatDateToDateAndTime } from "../../utils/reUsableFun";
import { useQuery } from "@tanstack/react-query";
import { FaUserCheck } from "react-icons/fa6";
import { toast } from "react-toastify";
import userAvatar from "../../assets/static/avatar.png";

const ReviewAndRatings = ({ product_id, category_id }) => {
  const { user } = useAuth();
  const { globalDs } = useGlobalProvider();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    setValues,
    setFieldError,
    setTouched,
  } = useFormik({
    initialValues: rating_form,
    validationSchema: ratings_validation,
    onSubmit: async (values, { setSubmitting }) => {
      await handleRatingSubmit();
      setSubmitting(false);
    },
  });

  const handleRatingClick = (value) => {
    setFieldValue("ratings", Number(value));
  };

  const getRatingFetch = async () =>
    await makeGetRequest(getRatingsByProduct(product_id, category_id));
  const { data: ratingsDataSource } = useQuery({
    queryKey: ["__getRatingsById__", category_id, product_id],
    queryFn: () => getRatingFetch(),
    enabled: !!category_id && !!product_id,
  });

  useEffect(() => {
    if (isValidValue(category_id) && isValidValue(product_id)) {
      const formState = {
        category_id: category_id,
        product_id: product_id,
        ratings: 5,
        reviews: values.reviews,
        rated_by: user?.id,
        rated_by_name: globalDs?.personalDetails?.user_name,
      };
      setValues(formState);
    }
  }, [category_id, product_id]);

  const handleRatingSubmit = async () => {
    if (isValidValue(user.id)) {
      const response = await makePostRequest(addRatings, values);
      if (response.status === statusSuccess) {
        toast.success(response.data);
        setFieldValue("reviews", "");
        setFieldError("reviews", "");
        setTouched("reviews", false);
        setFieldValue("ratings", 5);
      } else {
        toast.error(somethingWentWrong);
        setFieldValue("reviews", "");
      }
    } else {
      alert("Please login to continue this action");
    }
  };

  return (
    <div className="" id="review" role="tabpanel" aria-labelledby="review-tab">
      {ratingsDataSource &&
      ratingsDataSource?.data &&
      isValidArrayWithData(ratingsDataSource?.data) ? (
        ratingsDataSource?.data.map((data) => (
          <div className="mt-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {/* <FaUserCheck className="h-11 w-11 rounded-full shadow" /> */}
                <img
                  src={userAvatar}
                  className="h-11 w-11 rounded-full shadow"
                  alt=""
                />

                <div className="ms-3 flex-1">
                  <p className="text-lg font-semibold hover:text-orange-500 duration-500">
                    {data.name}
                  </p>
                  <p className="text-sm text-slate-400">
                    {formatDateToDateAndTime(data.date)}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
              <ul className="list-none inline-block text-orange-400">
                {Array.from(Array(data?.ratings || 0), (e, i) => {
                  return (
                    <li className="inline" key={i}>
                      <i className="mdi mdi-star text-lg"></i>
                    </li>
                  );
                })}

                <li className="inline text-slate-400 font-semibold">
                  {data?.ratings}
                </li>
              </ul>

              <p className="text-slate-400 italic">"{data?.reviews}"</p>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center">No Reviews for this product</p>
      )}

      <div className="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
        <h5 className="text-lg font-semibold">Ratings :</h5>

        <div className="mt-8">
          <div className="rating">
            {ratingsMockData.map((data, index) => (
              <div
                key={data.index}
                className={`rating__star ${
                  values.ratings === data.value ? "active" : ""
                }`}
                data-value={data.value}
                title={data.title}
                onClick={() => handleRatingClick(data.value)}
              >
                {data.name}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-1">
            <div className="mb-5">
              <div className="text-start">
                <label htmlFor="comments" className="font-semibold">
                  Your Comment:
                </label>
                <div className="form-icon relative mt-2">
                  <i
                    data-feather="message-circle"
                    className="w-4 h-4 absolute top-3 start-4"
                  ></i>
                  <textarea
                    name="comments"
                    id="comments"
                    className="ps-5 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                    placeholder="Message :"
                    value={values.reviews}
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "reviews", value: e.target.value },
                      })
                    }
                    onChange={(e) =>
                      handleChange({
                        target: { name: "reviews", value: e.target.value },
                      })
                    }
                  ></textarea>
                  {errors.reviews && touched.reviews && (
                    <p className="error-message">{errors.reviews}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
            onClick={handleSubmit}
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewAndRatings;
