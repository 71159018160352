import React, { useState, useRef, useEffect } from "react";
import arrivals3 from "../../assets/static/newArrivals/sample3.jpg";
import arrivals4 from "../../assets/static/newArrivals/sample4.jpg";
import arrivals7 from "../../assets/static/newArrivals/sample7.jpg";
import arrivals8 from "../../assets/static/newArrivals/sample8.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import ProductSlider from "./components/productCarosel";
import LandingCarosel from "./components/landingCarosel";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { makeGetRequest } from "../../Api/requestMethods";
import { popularAndNewArrival } from "../../Api/api";
import { adminProductsImagePath } from "../../config/config";
import { useGlobalProvider } from "../../global/provider";
import { useAuth } from "../../contexts/auth";
import useWishlist from "../../hooks/useWishList";
import { encryptData } from "../../utils/cryptoUtils";
import { scrollToTop } from "../../utils/reUsableFun";

const Home = () => {
  const { user } = useAuth();
  const { addToWishlist, removeFromWishlist, updateWishlist } = useWishlist();
  const { globalDs } = useGlobalProvider();
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const fetchNewArrivalAndPop = async () =>
    await makeGetRequest(popularAndNewArrival);
  const { data: newArrivalAndPop } = useQuery({
    queryKey: ["__newAndPop__"],
    queryFn: () => fetchNewArrivalAndPop(),
    //staleTime: 60 * 1000 * 5
  });

  // Direct ProductDetails

  const productsDetailsNavigate = (productId, categoryId) => {
    navigate(
      `/ProductDetailedView?cid=${encryptData(categoryId)}&pid=${encryptData(
        productId
      )}`
    );
    console.log(productId, categoryId);
  };

  const CategoryEvent = (data) => {
    console.log(data, "data");

    const encryptedData = encryptData(data.category_id);
    navigate(`/productDetails?id=${encryptedData}`);
  };

  const addToWishlisting = (data, isWishlistItem) => {
    isWishlistItem
      ? removeFromWishlist(data)
      : addToWishlist(data, isWishlistItem);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <section className="relative">
        <LandingCarosel />
      </section>

      <section class="relative md:py-12 pt-2 pb-2 ">
        <div className=" py-6">
          <ProductSlider
            categoryDataSource={globalDs.categoryDataSource}
            CategoryEvent={CategoryEvent}
          />
        </div>

        {/* New Arrivals */}

        <div class="container relative md:mt-24 mt-16">
          <div class="grid grid-cols-1 justify-center text-center mb-6">
            <h5 class="font-semibold text-3xl leading-normal mb-4">
              New Arrival Products
            </h5>
            <p class="text-slate-400 max-w-xl mx-auto">
              Shop the latest products from the most popular collections
            </p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
            {newArrivalAndPop &&
              newArrivalAndPop.data &&
              newArrivalAndPop.data.length > 0 &&
              newArrivalAndPop.data
                .filter((data) => data.is_new_arrival === 1)
                .map((data, index) => {
                  const images = JSON.parse(data.products_image)[0];
                  const matchingWishlistItem =
                    globalDs.wishlistDataSource &&
                    globalDs.wishlistDataSource.length > 0 &&
                    globalDs.wishlistDataSource.find(
                      (wishlistItem) =>
                        wishlistItem.products_id === data.products_id
                    );
                  const isWishlistItem = !!matchingWishlistItem;

                  return (
                    <div
                      key={index}
                      className="group"
                      onClick={() =>
                        productsDetailsNavigate(
                          data.products_id,
                          data.category_id
                        )
                      }
                    >
                      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                        <img
                          src={`${adminProductsImagePath}${images}`}
                          className="group-hover:scale-110 duration-500"
                          alt=""
                        />
                        <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                          <li>
                            <div
                              className={`size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full ${
                                isWishlistItem
                                  ? "bg-green-500 text-white hover:bg-green-600"
                                  : "bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                addToWishlisting(data, isWishlistItem);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-heart size-4"
                                style={{
                                  color: isWishlistItem ? "#8cc33f" : "red",
                                }}
                              >
                                <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                              </svg>
                            </div>
                          </li>
                        </ul>
                        <ul className="list-none absolute top-[10px] start-4">
                          <li>
                            <a
                              href="javascript:void(0)"
                              className="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5"
                            >
                              {data?.discount_percentage}% Off
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4">
                        <a
                          href="product-detail-one.html"
                          className="hover:text-orange-500 text-lg font-medium"
                        >
                          {data?.products_name}
                        </a>
                        <div className="flex justify-between items-center mt-1">
                          <p>
                            ₹{data?.real_price}{" "}
                            <del className="text-slate-400">
                              ₹{data?.fake_price}
                            </del>
                          </p>
                          <ul className="font-medium text-amber-400 list-none">
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                            <li className="inline">
                              <i className="mdi mdi-star"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <div class="container-fluid relative md:mt-24 mt-16">
          <div class="grid grid-cols-1">
            <div class="relative overflow-hidden py-24 px-4 md:px-10 bg-orange-600 bg-[url('../../assets/static/cover.png')] bg-center bg-no-repeat bg-cover">
              <div class="absolute inset-0 Shape bg-center bg-no-repeat bg-cover"></div>
              <div class="grid grid-cols-1 justify-center text-center relative z-1">
                <h3 class="text-4xl leading-normal tracking-wide font-bold text-white">
                  End of Season Clearance <br /> Sale upto 30%
                </h3>
                <div id="countdown" class="mt-6">
                  <ul class="count-down list-none inline-block space-x-1">
                    <li
                      id="days"
                      class="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                    ></li>
                    <li
                      id="hours"
                      class="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                    ></li>
                    <li
                      id="mins"
                      class="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                    ></li>
                    <li
                      id="secs"
                      class="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                    ></li>
                    <li id="end" class="h1"></li>
                  </ul>
                </div>
                <div class="mt-4">
                  <a
                    href="sale.html"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-white text-orange-500 rounded-md"
                  >
                    <i class="mdi mdi-cart-outline"></i> Shop Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container relative md:mt-24 mt-16">
          <div class="grid items-end md:grid-cols-2 mb-6">
            <div class="md:text-start text-center">
              <h5 class="font-semibold text-3xl leading-normal mb-4">
                Popular Items
              </h5>
              <p class="text-slate-400 max-w-xl">Popular items in this week</p>
            </div>

            <div class="md:text-end hidden md:block">
              <a
                href="shop-grid.html"
                class="text-slate-400 hover:text-orange-500"
              >
                See More Items <i class="mdi mdi-arrow-right"></i>
              </a>
            </div>
          </div>

          <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
            <div class="group">
              <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                <img
                  src={arrivals8}
                  class="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                  <a
                    href="shop-cart.html"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-heart size-4"
                      >
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                      </svg>
                    </a>
                  </li>
                  <li class="mt-1">
                    <a
                      href="shop-item-detail.html"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye size-4"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </a>
                  </li>
                  <li class="mt-1">
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark size-4"
                      >
                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>

                <ul class="list-none absolute top-[10px] start-4">
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5"
                    >
                      New
                    </a>
                  </li>
                </ul>
              </div>

              <div class="mt-4">
                <a
                  href="product-detail-one.html"
                  class="hover:text-orange-500 text-lg font-medium"
                >
                  Nature scenery
                </a>
                <div class="flex justify-between items-center mt-1">
                  <p>
                    ₹1634.00 <del class="text-slate-400">₹2441.00</del>
                  </p>
                  <ul class="font-medium text-amber-400 list-none">
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="group">
              <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                <img
                  src={arrivals7}
                  class="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                  <a
                    href="shop-cart.html"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-heart size-4"
                      >
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                      </svg>
                    </a>
                  </li>
                  <li class="mt-1">
                    <a
                      href="shop-item-detail.html"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye size-4"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </a>
                  </li>
                  <li class="mt-1">
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark size-4"
                      >
                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="mt-4">
                <a
                  href="product-detail-one.html"
                  class="hover:text-orange-500 text-lg font-medium"
                >
                  Meditation shiva
                </a>
                <div class="flex justify-between items-center mt-1">
                  <p>
                    ₹16.00 <del class="text-slate-400">₹21.00</del>
                  </p>
                  <ul class="font-medium text-amber-400 list-none">
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="group">
              <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                <img
                  src={arrivals4}
                  class="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                  <a
                    href="shop-cart.html"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-heart size-4"
                      >
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                      </svg>
                    </a>
                  </li>
                  <li class="mt-1">
                    <a
                      href="shop-item-detail.html"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye size-4"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </a>
                  </li>
                  <li class="mt-1">
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark size-4"
                      >
                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="mt-4">
                <a
                  href="product-detail-one.html"
                  class="hover:text-orange-500 text-lg font-medium"
                >
                  photo colleage
                </a>
                <div class="flex justify-between items-center mt-1">
                  <p>
                    ₹1346.00 <del class="text-slate-400">₹2341.00</del>
                  </p>
                  <ul class="font-medium text-amber-400 list-none">
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="group">
              <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                <img
                  src={arrivals3}
                  class="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                  <a
                    href="shop-cart.html"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-heart size-4"
                      >
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                      </svg>
                    </a>
                  </li>
                  <li class="mt-1">
                    <a
                      href="shop-item-detail.html"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-eye size-4"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </a>
                  </li>

                  <li class="mt-1">
                    <a
                      href="javascript:void(0)"
                      class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bookmark size-4"
                      >
                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="mt-4">
                <a
                  href="product-detail-one.html"
                  class="hover:text-orange-500 text-lg font-medium"
                >
                  dhakshina moorthy
                </a>
                <div class="flex justify-between items-center mt-1">
                  <p>
                    ₹1346.00 <del class="text-slate-400">₹2341.00</del>
                  </p>
                  <ul class="font-medium text-amber-400 list-none">
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                    <li class="inline">
                      <i class="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 mt-6">
            <div class="text-center md:hidden block">
              <a
                href="shop-grid.html"
                class="text-slate-400 hover:text-orange-500"
              >
                See More Items <i class="mdi mdi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
