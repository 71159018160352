import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const Faq = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeSection, setActiveSection] = useState("tech");

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["tech", "general", "payment", "support"];

      // Find which section is currently most visible in the viewport
      const current = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          // Consider a section "active" if it's top is within 200px of the viewport top
          return rect.top >= -200 && rect.top <= 200;
        }
        return false;
      });

      if (current) {
        setActiveSection(current);
      }
    };

    const handleSmoothScroll = (e) => {
      const navLinks = document.querySelectorAll(".navbar-link");
      navLinks.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const targetId = link.getAttribute("href").slice(1);
          setActiveSection(targetId);
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            const offset = 100;
            const targetPosition =
              targetElement.getBoundingClientRect().top +
              window.pageYOffset -
              offset;
            window.scrollTo({
              top: targetPosition,
              behavior: "smooth",
            });
          }
        });
      });
    };

    handleSmoothScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleAccordion = (accordionId) => {
    setActiveAccordion(activeAccordion === accordionId ? null : accordionId);
  };

  return (
    <section className="relative md:py-24 py-16">
      <div className="container relative">
        <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
          {/* Sidebar Navigation */}
          <div className="lg:col-span-4 md:col-span-5">
            <div className="rounded-md shadow dark:shadow-gray-800 p-6 sticky top-20">
              <ul className="list-none sidebar-nav mb-0 py-0" id="navmenu-nav">
                {["tech", "general", "payment", "support"].map(
                  (section, index) => (
                    <li
                      key={section}
                      className={`navbar-item ${index > 0 ? "mt-3" : ""} p-0`}
                    >
                      <a
                        href={`#${section}`}
                        className={`text-base font-medium navbar-link transition-colors ${
                          activeSection === section
                            ? "text-orange-500"
                            : "hover:text-orange-500"
                        }`}
                      >
                        {section.charAt(0).toUpperCase() + section.slice(1)}{" "}
                        Questions
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>

          {/* FAQ Content */}
          <div className="lg:col-span-8 md:col-span-7">
            {["tech", "general", "payment", "support"].map(
              (section, sectionIndex) => (
                <div
                  key={section}
                  id={section}
                  className={sectionIndex > 0 ? "mt-8" : ""}
                >
                  <h5 className="text-2xl font-semibold">
                    {section === "tech"
                      ? "Buying Product"
                      : `${
                          section.charAt(0).toUpperCase() + section.slice(1)
                        } Questions`}
                  </h5>

                  <div className="mt-6">
                    {[1, 2, 3, 4].map((item) => {
                      const accordionId = `${section}-${item}`;
                      return (
                        <div
                          key={accordionId}
                          className={`relative shadow dark:shadow-gray-800 rounded-md overflow-hidden ${
                            item > 1 ? "mt-4" : ""
                          }`}
                        >
                          <h2 className="text-base font-semibold">
                            <button
                              type="button"
                              className="flex justify-between items-center p-5 w-full font-medium text-start hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
                              onClick={() => toggleAccordion(accordionId)}
                            >
                              <span>How does it work?</span>
                              <svg
                                className={`size-4 shrink-0 transition-transform duration-200 ${
                                  activeAccordion === accordionId
                                    ? "rotate-180"
                                    : ""
                                }`}
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </h2>
                          <div
                            className={`transition-all duration-200 ${
                              activeAccordion === accordionId
                                ? "block"
                                : "hidden"
                            }`}
                          >
                            <div className="p-5">
                              <p className="text-slate-400 dark:text-gray-400">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form.
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="container relative md:mt-24 mt-16">
        <div className="grid grid-cols-1 text-center">
          <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            Have Question ? Get in touch!
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto">
            Upgrade your style with our curated sets. Choose confidence, embrace
            your unique look.
          </p>

          <div className="mt-6">
            <Link
              to={"/contact_support"}
              className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 hover:bg-orange-600 text-white rounded-md me-2 mt-2 transition-colors"
            >
              <FeatherIcon icon="phone" className="h-4 w-4 me-2" />
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
