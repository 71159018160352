import React from "react";
import Qty from "../../../components/Qty/Qty";
import ImageUploader from "../../../components/imageUploader/imageUploader";
import SelectBox from "../../../components/selectBox/selectBox";
import ShopNow from "../../../components/shopNow/shopNow.jsx";

const CommonComponent = ({
  product_id,
  productDetails,
  attributeChangeEvent,
  handleSubmit,
  setShowEvent,
  handleBlur,
  handleChange,
  values,
  setFieldValue,
  onImageUpload,
}) => {
  return (
    <>
      <div className="row">
        <div className="mt-1 col-lg-7 pe-0">
          <label
            htmlFor="state"
            className="block font-bold text-gray-600 text-xs "
          >
            Select Size / Material Type
          </label>
          <SelectBox
            placeholder="Select Size / Material Type"
            dataSource={productDetails?.category_attributes || []}
            displayExpr="attributeType"
            valueExpr="attributeType"
            onFocusOut={(value) =>
              handleBlur({
                target: { name: "attributeType", value: value },
              })
            }
            onValueChanged={(value) =>
              handleChange({
                target: { name: "attributeType", value: value },
              })
            }
          />
        </div>
        <ImageUploader onImageUpload={onImageUpload} />
      </div>
      <Qty setFieldValue={setFieldValue} values={values} />

      <ShopNow handleSubmit={handleSubmit} setShowEvent={setShowEvent} />
    </>
  );
};

export default CommonComponent;
