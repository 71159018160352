import React from "react";

const ShopNow = ({ handleSubmit, setShowEvent }) => {
  const onEventHandler = (isFromShopNow) => {
    setShowEvent(isFromShopNow);
    handleSubmit();
  };
  return (
    <div class="mt-4 space-x-1">
      <button
        class="py-2 px-5
inline-block font-semibold tracking-wide align-middle text-base
text-center bg-orange-500 text-white rounded-md mt-2"
        onClick={() => onEventHandler(true)}
        type="button"
      >
        Shop Now
      </button>

      <button
        onClick={() => onEventHandler(false)}
        className="py-2 px-5
inline-block font-semibold tracking-wide align-middle text-base
text-center rounded-md bg-orange-500/5 hover:bg-orange-500
text-orange-500 hover:text-white mt-2"
        type="button"
      >
        Add to Cart
      </button>
    </div>
  );
};

export default ShopNow;
