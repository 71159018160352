import React, { useEffect, useState, useRef } from "react";
import { CgProfile } from "react-icons/cg";
import BrandLogo from "../assets/static/logo/logo_darkMode.png";
import "./header.css";
import Search from "../assets/static/icons/Searchbar.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/auth";
import { useQuery } from "@tanstack/react-query";
import { useGlobalProvider } from "../global/provider";
import { makeGetRequest } from "../Api/requestMethods";
import { getWishList, userDetails } from "../Api/api";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import useAddToCart from "../hooks/useAddToCart";
import { statusSuccess } from "../global/constants";
import { toast } from "react-toastify";
import { somethingWentWrong } from "../global/constants";
import { viewCategory } from "../Api/api";
import { isValidArrayWithData, isValidValue } from "../utils/reUsableFun";
import { encryptData } from "../utils/cryptoUtils";
import { FiSearch } from "react-icons/fi";
import { globalSearch } from "../Api/api";

const Header = () => {
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { fetchCountCount } = useAddToCart();
  const { globalDs, setGlobalDs, globalSettings } = useGlobalProvider();
  const [settings, setSettings] = useState({
    navigationToggle: false,
    profilePicToggle: false,
    searchBarToggle: false,
    currentElementIndex: null,
    isSearchResultsVisible: false,
    searchString: "",
    searchResultDataSource: "",
  });

  const profilepicEvent = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      profilePicToggle: !prevSettings.profilePicToggle,
    }));
  };

  const fetchWishlist = async () => await makeGetRequest(getWishList(user?.id));
  const { data: wishlistDataSource, isLoading } = useQuery({
    queryKey: ["__getwishlist__", globalSettings.whislistDeleteTrigger],
    queryFn: () => fetchWishlist(),
    enabled: !!user?.id,
  });

  const fetchAccountDetails = async () =>
    await makeGetRequest(userDetails(user?.id));
  const { data: accountDetailsData } = useQuery({
    queryKey: ["__accountDetails__"],
    queryFn: () => fetchAccountDetails(),
    enabled: !!user?.id,
  });

  const fetchQuery = async () => await makeGetRequest(viewCategory);
  const { data: categoryDataSource } = useQuery({
    queryKey: ["usercategoryDetails"],
    queryFn: () => fetchQuery(),
    //staleTime: 60 * 1000 * 5
  });

  useEffect(() => {
    if (isValidArrayWithData(categoryDataSource?.data)) {
      setGlobalDs((prev) => ({
        ...prev,
        categoryDataSource: categoryDataSource || [],
      }));
    }
  }, [categoryDataSource]);

  useEffect(() => {
    (async () => {
      const response = await fetchCountCount(user);
      if ((response && response.status === statusSuccess) || response === 0) {
        setGlobalDs((prev) => ({
          ...prev,
          cartCount: response === 0 ? response : response?.data?.count,
        }));
      } else {
        toast.error(somethingWentWrong);
      }
    })();
  }, []);

  useEffect(() => {
    if (accountDetailsData) {
      const accountData =
        accountDetailsData?.data && accountDetailsData?.data[0];
      setGlobalDs((prev) => ({ ...prev, personalDetails: accountData }));
    }
  }, [accountDetailsData]);

  useEffect(() => {
    setGlobalDs((prev) => ({ ...prev, isWishlistLoading: isLoading }));
  }, []);

  useEffect(() => {
    if (wishlistDataSource) {
      const parsedWishlist =
        wishlistDataSource?.data && wishlistDataSource?.data?.length > 0
          ? wishlistDataSource?.data?.map((data) => {
              return {
                products_name: data?.products_name,
                products_id: data?.products_id,
                products_image: JSON.parse(data?.products_image)[0],
                real_price: data?.real_price,
                fake_price: data?.fake_price,
              };
            })
          : [];
      setGlobalDs((prev) => ({
        ...prev,
        wishlistDataSource: parsedWishlist,
        isWishlistLoading: isLoading,
      }));
    }
  }, [wishlistDataSource]);

  const onSearchEvent = (data, isSearchResult) => {
    try {
      if (!isSearchResult) {
        const encryptedData = encryptData(data.category_id);
        navigate(`/productDetails?id=${encryptedData}`);
      } else {
        navigate(
          `/ProductDetailedView?cid=${encryptData(
            data.category_id
          )}&pid=${encryptData(data.products_id)}`
        );
      }
      setSettings((prev) => ({
        ...prev,
        isSearchResultsVisible: false,
        searchResultDataSource: [],
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const mouseOverEvent = (index) => {
    setSettings((prev) => ({ ...prev, currentElementIndex: index }));
  };

  const onSearchClickEvent = () => {
    setSettings((prev) => ({
      ...prev,
      isSearchResultsVisible: !prev.isSearchResultsVisible,
    }));
  };

  const onSearchChange = (e) => {
    try {
      setSettings((prev) => ({ ...prev, searchString: e.target.value }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await makeGetRequest(globalSearch(settings.searchString));
        if (response) {
          setSettings((prev) => ({
            ...prev,
            searchResultDataSource: response.data,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (isValidValue(settings.searchString)) {
      fetchSearchResults();
    }
  }, [settings.searchString]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          profilePicToggle: false,
        }));
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setSettings]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          isSearchResultsVisible: false,
          searchResultDataSource : [],
          searchString : ""
        }));
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setSettings]);

  const enableLogin = () => {
    setGlobalDs((prev) => ({ ...prev, isLoginEnabled: !prev.isLoginEnabled }));
  };

  const navigateToWishlist = () => {
    if (user?.id) {
      navigate("/account/wishlist");
    } else {
      setGlobalDs((prev) => ({
        ...prev,
        isLoginEnabled: !prev.isLoginEnabled,
      }));
    }
  };

  return (
    <>
      <div className="dark:bg-slate-900">
        <nav
          id="topnav"
          dir="ltr"
          className="defaultscroll is-sticky tagline-height nav-sticky brandColor"
        >
          <div className="container relative flex justify-between items-center">
            <Link to="/">
              <img
                src={BrandLogo}
                className="brandLogoImage inline-block dark:hidden"
                alt=""
              />
            </Link>
            <div
              className="hidden md:flex search items-center space-x-4"
              ref={searchRef}
            >
              <div className="relative w-full">
                <input
                  autoComplete="off"
                  type="text"
                  pattern="\S+.*"
                  name="input"
                  id="input"
                  className="text-field inputPlace"
                  placeholder="Search by product name or category"
                  onClick={onSearchClickEvent}
                  onChange={onSearchChange}
                  value={settings.searchString}
                />
                <div
                  className={`RecentSearch absolute ${
                    !settings?.isSearchResultsVisible ? "hidden" : ""
                  }`}
                  id="search"
                >
                  <ul>
                    {settings.searchResultDataSource &&
                    isValidArrayWithData(settings.searchResultDataSource)
                      ? settings.searchResultDataSource.map((data, index) => (
                          <li
                            className={`flex list align-middle mb-2 p-1 rounded-pill ${
                              settings.currentElementIndex === index
                                ? "onSearchResulthover"
                                : ""
                            }`}
                            key={index}
                            onClick={() => onSearchEvent(data, true)}
                            style={{ cursor: "pointer" }}
                            onMouseOver={() => mouseOverEvent(index)}
                          >
                            <FiSearch className="text-muted mt-1 me-2" />
                            <span>
                              {data?.products_name || data?.category_name}
                            </span>
                          </li>
                        ))
                      : categoryDataSource &&
                        categoryDataSource.data &&
                        categoryDataSource.data.map((data, index) => (
                          <li
                            className={`flex list align-middle mb-2 p-1 rounded-pill ${
                              settings.currentElementIndex === index
                                ? "onSearchResulthover"
                                : ""
                            }`}
                            key={index}
                            onClick={() => onSearchEvent(data, false)}
                            style={{ cursor: "pointer" }}
                            onMouseOver={() => mouseOverEvent(index)}
                          >
                            <FiSearch className="text-muted mt-1 me-2" />
                            <span>{data.category_name}</span>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
              <button className="search-btn searchBtn flex">
                <img src={Search} alt="Search Icon" />
              </button>
            </div>

            <ul className="buy-button list-none mb-0 flex space-x-4">
              <li className="dropdown inline-block relative ms-2">
                <button
                  data-dropdown-toggle="dropdown"
                  className="dropdown-toggle size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 border border-orange-500 bg-white brandColor cartBTN"
                  type="button"
                  onClick={() => navigate("/cart")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-shopping-cart"
                  >
                    <circle cx="9" cy="21" r="1"></circle>
                    <circle cx="20" cy="21" r="1"></circle>
                    <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                  </svg>
                  <span className="cartCountPosition">
                    {(globalDs && globalDs?.cartCount) || 0}
                  </span>
                </button>
              </li>

              <li
                className="inline-block relative ms-2"
                onClick={navigateToWishlist}
              >
                <a
                  href="javascript:void(0)"
                  className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500  bg-white brandColor"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-heart h-4 w-4"
                  >
                    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                  </svg>
                </a>
              </li>

              {user?.id ? (
                <li
                  className="dropdown inline-block relative ms-2"
                  ref={dropdownRef}
                >
                  <button
                    onClick={profilepicEvent}
                    className="dropdown-toggle items-center"
                    type="button"
                  >
                    <div className="text-white">
                      <CgProfile style={{ height: "34px", width: "auto" }} />
                    </div>
                  </button>

                  <div
                    className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${
                      settings.profilePicToggle ? "block" : "hidden"
                    }`}
                  >
                    <ul className="py-2 text-start">
                      <li>
                        <p className="text-slate-400 pt-2 px-4">
                          Welcome {globalDs?.personalDetails?.user_name}!
                        </p>
                      </li>
                      <li>
                        <Link
                          to="account/AccountDetails"
                          className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white"
                        >
                          <FeatherIcon icon="user" className="h-4 w-4 me-2" />
                          Account
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/help_center"
                          className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white"
                        >
                          <FeatherIcon
                            icon="help-circle"
                            className="h-4 w-4 me-2"
                          />
                          Helpcenter
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact_support"
                          className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white"
                        >
                          <FeatherIcon icon="phone" className="h-4 w-4 me-2" />
                          Contact Support
                        </Link>
                      </li>
                      <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                      <li style={{ cursor: "pointer" }}>
                        <p
                          onClick={() => signOut()}
                          className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white"
                        >
                          <FeatherIcon
                            icon="log-out"
                            className="h-4 w-4 me-2"
                          />
                          Logout
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                <button
                  className="ms-3 btn btn-light Color"
                  onClick={enableLogin}
                >
                  Login
                </button>
              )}
            </ul>
          </div>

          {/* <div className="container flex md:hidden mt-0 mb-2 mx-2">
            <form className="relative w-full">
              <input
                autocomplete="off"
                type="text"
                pattern="\S+.*"
                name="input"
                id="input"
                className="text-field inputPlace w-full"
                placeholder="Search"
              />
              <div
                className="RecentSearch absolute w-full  md:block"
                id="search"
              >
                <p className="flex">Recent Search</p>
                <ul>
                  <li className="flex list align-middle">
                    <img src={recentIcon} />
                    <span>list of recent search</span>
                  </li>
                  <li className="flex list align-middle">
                    <img src={recentIcon} />
                    <span>list of recent search</span>
                  </li>
                  <li className="flex list align-middle">
                    <img src={recentIcon} />
                    <span>list of recent search</span>
                  </li>
                  <li className="flex list align-middle">
                    <img src={recentIcon} />
                    <span>list of recent search</span>
                  </li>
                </ul>
              </div>
            </form>
            <button className="search-btn btn searchBtn flex ml-2">
              <img src={Search} />
            </button>
          </div> */}
        </nav>
      </div>
    </>
  );
};

export default Header;
