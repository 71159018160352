import React from "react";
import FeatherIcon from "feather-icons-react";
import { useNavigate, Link } from "react-router-dom";

const ContactSupport = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="relative lg:py-24 py-16">
        <div class="container">
          <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div class="lg:col-span-7 md:col-span-6">
              <img src="https://shreethemes.in/cartzio/layouts/assets/images/contact.svg" alt="" />
            </div>

            <div class="lg:col-span-5 md:col-span-6">
              <div class="lg:ms-5">
                <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 class="mb-6 text-2xl leading-normal font-semibold">
                    Get in touch !
                  </h3>

                  <div
                    name="myForm"
                    id="myForm"
                  >
                    <p class="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div class="grid lg:grid-cols-12 grid-cols-1 gap-3">
                      <div class="lg:col-span-6">
                        <label for="name" class="font-semibold">
                          Your Name:
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Name :"
                        />
                      </div>

                      <div class="lg:col-span-6">
                        <label for="email" class="font-semibold">
                          Your Email:
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Email :"
                        />
                      </div>

                      <div class="lg:col-span-12">
                        <label for="subject" class="font-semibold">
                          Your Question:
                        </label>
                        <input
                          name="subject"
                          id="subject"
                          class="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Subject :"
                        />
                      </div>

                      <div class="lg:col-span-12">
                        <label for="comments" class="font-semibold">
                          Your Comment:
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          class="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Message :"
                        ></textarea>
                      </div>
                    </div>
                    <button
                     // type="submit"
                      id="submit"
                      name="send"
                      class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md mt-2"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid relative mt-20">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.774689926264!2d80.09348367507779!3d13.050008687272559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a528b0014cec313%3A0x3db48f10dfa67b4c!2sPlanet%2056!5e0!3m2!1sen!2sin!4v1730044804228!5m2!1sen!2sin"
              style={{ border: 0 }}
              className="w-full h-[500px]"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSupport;
