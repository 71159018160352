import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "../../../assets/static/icons/left.svg";
import right from "../../../assets/static/icons/right.svg";
import spider from "../../../assets/static/BANNER1.png";
import BANNER2 from "../../../assets/static/BANNER2.png";
import design from "../../../assets/static/BANNER3.png";
import shopImage from "../../../assets/static/BANNER4.png"
import MobileBanner1 from "../../../assets/static/MobileBanner1.png"
import MobileBanner2 from "../../../assets/static/MobileBanner2.png"
import MobileBanner3 from "../../../assets/static/MobileBanner3.png"
import MobileBanner4 from "../../../assets/static/MobileBanner4.png"

import BannerImage from "../../../assets/static/carouselImage4.jpg"



import "./landingCarosel.css";

export const data = [spider, design, shopImage, BANNER2, BannerImage];
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <img src={right} alt="Next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <img src={left} alt="Previous" />
    </div>
  );
}

function LandingCarosel() {
  return (
    <div style={{ marginTop: "70px" }} className="carousel">
      <Slider
        autoplay
        autoplaySpeed={2000}
        // dots
        initialSlide={2}
        infinite
        prevArrow={<SamplePrevArrow />}
        nextArrow={<SampleNextArrow />}
        customPaging={(i) => {
          return (
            <div>
              <img
                src={data[i]}
                alt=""
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </div>
          );
        }}
        dotsClass="slick-dots custom-indicator"
      >
        {data.map((item) => (
          <div>
            <img src={item} alt="" style={{ width: "100%", height: "40vh" }} />
          </div>
        ))}
      </Slider>
    </div>
    
  );
}

export default LandingCarosel;
