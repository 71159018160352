import { makePostRequest } from './requestMethods';

export async function signIn(email, password) {
  try {
    const body = {
       email: email,
      password: password
    }
    const response = await makePostRequest(body);
    return response;
  }
  catch (error) {
    return {
      status: error.response?.data?.status,
      message: error.response?.data?.message
    }
  }

}