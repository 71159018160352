// const BASEPATH = `http://192.168.1.2:3002`
// const IPCONFIG = `http://192.168.1.2`

// const adminCategoryImagePath = `${IPCONFIG}:8081/static/category_images/`;
// const adminProductsImagePath = `${IPCONFIG}:8081/static/products_images/`
// const productImagePath = `${IPCONFIG}:8081/static/products_images/`


// export {
//     BASEPATH,
//     adminCategoryImagePath,
//     productImagePath,
//     adminProductsImagePath
// }

const BASEPATH = `https://api.planet56.in`;
const IPCONFIG = `https://adminapi.planet56.in`

const adminCategoryImagePath = `${IPCONFIG}/static/category_images/`;
const adminProductsImagePath = `${IPCONFIG}/static/products_images/`
const productImagePath = `${IPCONFIG}/static/products_images/`


export {
    BASEPATH,
    adminCategoryImagePath,
    productImagePath,
    adminProductsImagePath
}