import React from "react";
import emptyCart from "../../assets/static/Cart.png";
import { Link } from "react-router-dom";

const CartEmpty = () => {
  return (
    <div className="centerDiv">
      <section class="relative bg-orange-500/5">
        <div class="container-fluid relative">
          <div class="grid grid-cols-1">
            <div class="flex flex-col min-h-screen justify-center md:px-10 py-10 px-4">
              <div class="text-center">
                <a href="index.html">
                  <img
                    src="assets/images/logo-dark.png"
                    class="mx-auto"
                    alt=""
                  />
                </a>
              </div>
              <div class="title-heading text-center m-auto">
                <img src={emptyCart} class="mx-auto w-72" alt="" />
                <h1 class="mt-8 mb-6 md:text-5xl text-3xl font-bold">
                  Cart is empty!
                </h1>
                <p class="text-slate-400">
                  Whoops, Cart is empty, please add products!.
                </p>

                <div class="mt-4">
                  <Link
                    to={"/"}
                    class="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-orange-500 hover:bg-orange-600 border-orange-500 hover:border-orange-600 text-white rounded-md"
                  >
                    Explore Products
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CartEmpty;
