import React from "react";

const ShippingPolicy = () => {
  return (
    <>
      <section class="relative md:py-24 py-16">
        <div class="container relative">
        <h1 style={{fontSize:"40px"}}><b>Shipping Policy:</b>
          </h1>
          <p>Orders are delivered as per the timeframe specifed during checkout. We adhere strictly to this schedule and will make every effort to deliver
            your order on time.</p>
          <p>Delivery Exceptions: In cases where delivery cannot be completed, our support team will notify you in the message section on the job page with
            the reason provided by the courier. Once you respond, we’ll forward your response to the courier for a reattempted delivery.
            If no response is received within a day, the package will be returned to our Production Unit.
            For reshipping, provide an alternate address with complete details, and we’ll calculate the reshipping cost and send a payment link.
            If the Product Status Shows 'Delivered' but Not Received:</p>
          <p>Contact us if your shipment shows "Delivered" but hasn’t arrived. We’ll help by requesting Proof of Delivery (POD) or Delivery Receipt Slip (DRS
            Copy) from the courier, which typically takes 4-5 business days.
            Meanwhile, please check with reception, security, or neighbors to confrm if they have received the shipment on your behalf.
            Damaged Product Received:</p>
          <p>If the exterior box/cover is tampered with, do not accept it. Take a photo and notify us while rejecting the delivery so we can initiate a new order.
            If the exterior is fne but the inside product is damaged, we’ll assist with managing it. If an unboxing video is available, a replacement order will
            be processed without question.</p>
          <p>Return Shipment Not Accepted / No Response for Reshipping:
          </p>

          <p>If no response is received within 30 days of goods being returned to the hub, the package will be scrapped/destroyed, and no refund will be
            issued.</p>
        </div>

      </section>
    </>
  );
};

export default ShippingPolicy;
