import CryptoJS from "crypto-js";

/**
 * Encrypts the given data using AES encryption.
 * @param {string} data - The data to encrypt.
 * @returns {string} - The encrypted data.
 */
const encryptData = (data) => {
  try {
    if (!data) throw new Error("No data provided for encryption");
    const encrypted = CryptoJS.AES.encrypt(
      data.toString(),
      process.env.REACT_APP_PARAMS_SECRET_CODE
    ).toString();
    return btoa(encrypted);
  } catch (error) {
    console.error("Error encrypting data:", error.message);
    return null;
  }
};

/**
 * Decrypts the given encrypted data using AES decryption.
 * @param {string} encryptedData - The encrypted data to decrypt.
 * @returns {string|null} - The decrypted data, or null if decryption fails.
 */
const decryptData = (encryptedData) => {
  try {
    if (!encryptedData)
      throw new Error("No encrypted data provided for decryption");
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_PARAMS_SECRET_CODE
    );
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (!decrypted) throw new Error("Failed to decrypt data");
    return decrypted;
  } catch (error) {
    console.error("Error decrypting data:", error.message);
    return null;
  }
};

export { encryptData, decryptData };
