import * as Yup from "yup";
import {
  requiredField,
  invalidEmail,
  passwordLength,
  nameLengthError,
  mobileLength,
} from "../global/constants";

const login_validation = Yup.object({
  email: Yup.string().email(invalidEmail).required(requiredField),
  password: Yup.string().min(6, passwordLength).required(requiredField),
});

const createAccount_validation = Yup.object({
  user_name: Yup.string().min(3, nameLengthError).required(requiredField),
  email: Yup.string().email(invalidEmail).required(requiredField),
  password: Yup.string().min(6, passwordLength).required(requiredField),
  mobile_number: Yup.string().required(requiredField),
});

const personalDetails_validation = Yup.object({
  user_name: Yup.string().min(3, nameLengthError).required(requiredField),
  last_name: Yup.string().required(requiredField),
  gender: Yup.string().required(requiredField),
});

const account_details_validation = Yup.object({
  name: Yup.string().required(requiredField),
  auth_user_id: Yup.number().required(requiredField),
  mobile: Yup.number().min(10, mobileLength).required(requiredField),
  country: Yup.string().required(requiredField),
  state: Yup.string().required(requiredField),
  city: Yup.string().required(requiredField),
  pincode: Yup.number().required(requiredField),
  address: Yup.string().required(requiredField),
  landmark: Yup.string().required(requiredField),
  address_type_isHome: Yup.number().required(requiredField),
});

const tempUser_validation = Yup.object().shape({
  user_name: Yup.string().min(3, nameLengthError).required(requiredField),
  email: Yup.string().email(invalidEmail).required(requiredField),
  otp: Yup.string().when("isotpEnabled", (isotpEnabled, schema) => {
    if (isotpEnabled[0] === true) return schema.required(requiredField);
    return schema;
  }),
});

const ratings_validation = Yup.object({
  category_id: Yup.string().required(requiredField),
  product_id: Yup.string().required(requiredField),
  ratings: Yup.number().required(requiredField),
  rated_by: Yup.number().required(requiredField),
  rated_by_name: Yup.string().required(requiredField),
  reviews: Yup.string().required(requiredField),
});

const commonComponent_validation = Yup.object({
  image: Yup.string().required(requiredField),
  attributeType: Yup.string().required(requiredField),
  qty: Yup.number().required(requiredField),
});

export {
  personalDetails_validation,
  tempUser_validation,
  login_validation,
  createAccount_validation,
  account_details_validation,
  ratings_validation,
  commonComponent_validation,
};
