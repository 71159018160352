import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { signIn as sendSignInRequest } from "../Api/auth";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

function AuthProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    const token = localStorage.getItem("__p56_Web_auth__");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return { id: decodedToken.id, token };
      } catch (error) {
        localStorage.removeItem("__p56_Web_auth__");
      }
    }
    return null;
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  const createToken = async (result) => {
    const token = result.token;
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.id;
    localStorage.setItem("__p56_Web_auth__", token);
    setUser({ id: userId, token });
    navigate("/");
  };

  const signIn = useCallback(
    async (email, password) => {
      const result = await sendSignInRequest(email, password);
      if (result.status === 200) {
        await createToken(result);
      }
      return result;
    },
    [navigate]
  );

  const signOut = useCallback(() => {
    setUser(null);
    localStorage.removeItem("__p56_Web_auth__");
    navigate("/");
    window.location.reload(false);
  }, [navigate]);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading, createToken }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth };
