import React from "react";
import { Link } from "react-router-dom";
import { registration_form } from "../../schemas/Forms";
import { createAccount_validation } from "../../schemas/Validations";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { postFetch, onChangeNumberOnly } from "../../utils/reUsableFun";
import { useFormik } from "formik";
import { registration } from "../../Api/api";

const CreateAccount = () => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: registration_form,
    validationSchema: createAccount_validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await registraionMutation.mutateAsync(values);
      setSubmitting(false);
    },
  });

  const registraionMutation = useMutation({
    mutationFn: (regData) => postFetch(registration, regData),
    onSuccess: (data) => {
      data.status === 200 || data.status === 201
        ? toast.success(data.message)
        : toast.error(data.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <section className="md:h-screen py-36 flex items-center bg-orange-500/10 dark:bg-orange-500/20 bg-[url('../../assets/images/hero/bg-shape.png')] bg-center bg-no-repeat bg-cover">
        <div class="container relative">
          <div class="grid grid-cols-1">
            <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
              <div class="d-flex items-center">
                <div class="relative w-auto d-none d-lg-block">
                  <img
                    class="h-full object-cover LoginImg"
                    src="http://192.168.137.1:8000/static/products_images/1718542508594-28251660-image_1718542508321.png"
                    alt=""
                  />
                </div>

                <div class="p-8 lg:px-20">
                  <form class="text-start lg:py-20 py-8">
                    <div class="grid grid-cols-1">
                      <div class="mb-4">
                        <label class="font-semibold">Your Name:</label>
                        <input
                          type="text"
                          class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Your name"
                          value={values.user_name}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "user_name",
                                value: e.target.value,
                              },
                            })
                          }
                          onBlur={(e) =>
                            handleBlur({
                              target: {
                                name: "user_name",
                                value: e.target.value,
                              },
                            })
                          }
                        />
                        {touched.user_name && errors.user_name ? (
                          <div className="fieldRequiredError text-red-500 text-sm">
                            {errors.user_name}
                          </div>
                        ) : null}
                      </div>

                      <div class="mb-4">
                        <label class="font-semibold">Email Address:</label>
                        <input
                          type="email"
                          class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Your Email"
                          value={values.email}
                          onChange={(e) =>
                            handleChange({
                              target: { name: "email", value: e.target.value },
                            })
                          }
                          onBlur={(e) =>
                            handleBlur({
                              target: { name: "email", value: e.target.value },
                            })
                          }
                        />
                        {touched.email && errors.email ? (
                          <div className="fieldRequiredError text-red-500 text-sm">
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div class="mb-4">
                        <label class="font-semibold">Mobile Number:</label>
                        <input
                          type="tel"
                          class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Your Mobile Number"
                          pattern="[0-9]*"
                          value={values.mobile_number}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (onChangeNumberOnly(value)) {
                              handleChange({
                                target: { name: "mobile_number", value },
                              });
                            }
                          }}
                          onBlur={(e) =>
                            handleBlur({
                              target: {
                                name: "mobile_number",
                                value: e.target.value,
                              },
                            })
                          }
                        />
                        {touched.mobile_number && errors.mobile_number ? (
                          <div className="fieldRequiredError text-red-500 text-sm">
                            {errors.mobile_number}
                          </div>
                        ) : null}
                      </div>

                      <div class="mb-4">
                        <label class="font-semibold">Password:</label>
                        <input
                          type="password"
                          class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Your Password"
                          value={values.password}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "password",
                                value: e.target.value,
                              },
                            })
                          }
                          onBlur={(e) =>
                            handleBlur({
                              target: {
                                name: "password",
                                value: e.target.value,
                              },
                            })
                          }
                        />
                        {touched.password && errors.password ? (
                          <div className="fieldRequiredError text-red-500 text-sm">
                            {errors.password}
                          </div>
                        ) : null}
                      </div>

                      <div class="mb-4">
                        <div class="flex items-center w-full mb-0">
                          <label
                            class="form-check-label text-slate-400"
                            for="AcceptT&C"
                          >
                            By continuing, you agree to planet56{" "}
                            <Link>Terms of Use</Link> and{" "}
                            <Link>Privacy Policy.</Link>
                          </label>
                        </div>
                      </div>

                      <div class="mb-4">
                        <button
                          class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
                          onClick={handleSubmit}
                          value="Register"
                          disabled={isSubmitting}
                          type="button"
                        >
                          Register
                        </button>
                      </div>

                      <div class="text-center">
                        <span class="text-slate-400 me-2">
                          Already have an account ?{" "}
                        </span>{" "}
                        <Link
                          to={"/login"}
                          class="text-black dark:text-white font-bold inline-block"
                        >
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateAccount;
