import React, { useEffect, useState } from "react";

const Qty = ({ setFieldValue, values }) => {
  const [counter, setCounter] = useState(0);

  const eventHandler = (isIncrement) => {
    if (isIncrement) {
      setCounter(counter + 1);
    } else {
      if (counter > 0) {
        setCounter(counter - 1);
      }
    }
  };

  useEffect(() => {
    if (counter >= 0) {
      setFieldValue("qty", counter);
    }
  }, [counter]);

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-4">
      <div className="flex items-center">
        <h5 className="text-lg font-semibold me-2">Quantity:</h5>
        <div className="qty-icons ms-3 space-x-0.5">
          <button
            onClick={() => eventHandler(false)}
            className="size-9 inline-flex items-center justify-center tracking-wide
            align-middle text-base text-center rounded-md bg-orange-500/5
            hover:bg-orange-500 text-orange-500 hover:text-white minus"
          >
            -
          </button>
          <input
            min={0}
            name="quantity"
            value={values?.qty || counter}
            type="number"
            className="h-9 inline-flex items-center
            justify-center tracking-wide align-middle text-base text-center
            rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500
            hover:text-white pointer-events-none w-16 ps-4 quantity"
            readOnly
          />
          <button
            onClick={() => eventHandler(true)}
            className="size-9 inline-flex items-center justify-center tracking-wide
            align-middle text-base text-center rounded-md bg-orange-500/5
            hover:bg-orange-500 text-orange-500 hover:text-white plus"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default Qty;
