import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/auth";
import Header from "./layout/header";
import Footer from "./layout/footer";
import {
  Home,
  About,
  Contact,
  Login,
  CreateAccount,
  ProductDetails,
  ProductDetailedView,
  ForgotPassword,
  ResetForm,
  ConfirmEmail,
  Account,
  Wishlist,
  Orders,
  AccountDetails,
  AddressDetails,
  Cart,
  CheckOut,
  LoginModal,
} from "./pages";
import { GlobalDataProvider } from "./global/provider";
import {
  HelpCenter,
  ContactSupport,
  Faq,
  AboutUs,
  TermsandConditions,
  ShippingPolicy,
  CancellationandRefunds,
  PrivacyPolicy,
} from "./components";
import WhatsApp from "./components/whatsapp/whatsApp";
import "./App.css";
function App() {
  return (
    <>
      <Router>
        <GlobalDataProvider>
          <AuthProvider>
            <Header />
            <LoginModal />
            <WhatsApp />

            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<> Page not found</>} />

              {/* Auth Routes */}
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/resetPassword" element={<ResetForm />} />
              <Route path="/createAccount" element={<CreateAccount />} />
              <Route path="/confirmEmail" element={<ConfirmEmail />} />

              {/* Account */}
              <Route path="/account" element={<Account />}>
                <Route path="wishlist" element={<Wishlist />} />
                <Route path="orders" element={<Orders />} />
                <Route path="accountDetails" element={<AccountDetails />} />
                <Route path="addressDetails" element={<AddressDetails />} />
              </Route>
              <Route path="cart" element={<Cart />} />

              <Route path="checkout" element={<CheckOut />} />

              {/* Pages Routes */}
              <Route path="/productDetails" element={<ProductDetails />} />
              <Route
                path="/ProductDetailedView"
                element={<ProductDetailedView />}
              />

              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Home />} />
              <Route path="about_me" element={<About />} />
              <Route path="/help_center" element={<HelpCenter />} />
              <Route path="/contact_support" element={<ContactSupport />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route
                path="/TermsandConditions"
                element={<TermsandConditions />}
              />
              <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
              <Route
                path="/CancellationandRefunds"
                element={<CancellationandRefunds />}
              />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            </Routes>

            <Footer />
          </AuthProvider>
        </GlobalDataProvider>
      </Router>
    </>
  );
}

export default App;
