import React from "react";

const TermsandConditions = () => {
  return (
    <>
      <section class="relative md:py-24 py-16">
        <div className="container">
          <h1 style={{fontSize:"40px"}}><b>Terms and Conditions</b>
          </h1>
          <h4>Acceptance of Terms
          </h4>
          <p>By using the Planet56 website ("Site"), you indicate your acceptance of these Terms and Conditions ("T&C").
            If you do not accept these T&C, please refrain from using this Site.
            Planet56 reserves the right to modify these T&C at any time. By continuing to use the Site after changes, you agree to accept the updated T&C.
            Ownership and Changes to Relationship</p>
          <p>Planet56 is owned by Planet56 Document Services Pvt. Ltd.
            Planet56 may change the nature of its relationship with users and revise these T&C as necessary. Users who violate the T&C may be banned
            from using the Site.
            Defnitions</p>
          <p>"Customer" or "you" refers to the person or company placing an order via the website to purchase products.
            "Products" refers to the products offered for sale on the Site.
            Cookies</p>
          <p>Cookies are small data fles that enhance your shopping experience, such as remembering your shopping cart or saving your password.
            By using the Site, you agree to the use of cookies as described in our Privacy Policy.
            Trademark, Copyright, and Restrictions</p>
          <p>All content on the Site, including images, text, audio, and video, is protected by copyrights and trademarks owned by Planet56 or licensed
            parties.
            You may not reproduce, distribute, or modify any material from the Site without prior written permission from Planet56, except for personal use
            when placing an order.
            Account and Registration Obligations</p>
          <p>You are responsible for maintaining the confdentiality of your account and password.
            You agree to provide accurate and current information during registration. If your information is found to be inaccurate, Planet56 may suspend
            or terminate your account.
            Damage Claims or Shortages</p>
          <p>Claims for damage or shortages must be reported within 48 hours of receiving the delivery.
            By using the Site, you agree to Planet56's Refund & Return Policy.
            Product Description</p>
          <p>Planet56 strives for accuracy, but does not guarantee that all product descriptions are accurate, complete, or error-free.
            If a product is not as described, your sole remedy is to return it in unused condition.
            Product Availability Clause</p>
          <p>If an ordered product is out of stock, Planet56 will notify you within 48 hours and either inform you when the product will be back in stock or
            cancel the order and refund your amount as per the Refund Policy.
            Cancellation by Planet56</p>
          <p>Planet56 reserves the right to cancel orders for various reasons, including inventory issues, errors in pricing or product information, or security
            concerns.
            If your order is canceled after payment, the amount will be refunded to your account.
            Credit Card Details</p>
          <p>You must provide accurate and valid credit card details for processing transactions.
            Planet56 is not responsible for any fraud or misuse of credit card details. The liability for fraudulent use lies with you.
            Fraudulent/Declined Transactions</p>
          <p>Planet56 reserves the right to recover costs and initiate legal action against individuals who fraudulently use the Site.
            Eligibility</p>
          <p>You must be legally eligible to enter into contracts and be authorized to bind other parties to this agreement.
            User Responsibilities</p>
          <p>You agree to provide true and accurate information when using the Site.
            You must comply with applicable laws and avoid unlawful activities on the Site, such as harassing or threatening other users.
            Objectionable Material
          </p>
          <p>You acknowledge that you may encounter offensive or objectionable content on the Site. Planet56 will not be liable for such content.
            Payment Services</p>
          <p>Planet56 is not liable for any loss or damage resulting from the decline of a transaction or exceeding your credit card limit.
            User Account Data
          </p>
          <p>Planet56 protects personal information in accordance with its Privacy Policy.
            You are responsible for safeguarding your account and password. Planet56 may suspend accounts if the information provided is found to be
            inaccurate or misleading.
            Voucher Codes</p>
          <p>Planet56 reserves the right to limit the use of voucher codes and restrict their usage per customer.
            Voucher codes are for one-time use only by the individual customer.
            Contests and Promotions</p>
          <p>Specifc Terms and Conditions apply to any contests or promotions hosted on the Site. In case of confict between these T&C and the contest
            T&C, the contest T&C will prevail.</p>
        </div>
      </section>
    </>
  );
};

export default TermsandConditions;
