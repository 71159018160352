import React, { useState } from "react";

const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
      id="accordion-collapse"
      data-accordion="collapse"
      className="col-lg-6 mt-6"
    >
      {data.map((item, index) => (
        <div
          className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
          key={index}
        >
          <h2
            className="text-base font-semibold"
            id={`accordion-heading-${index}`}
          >
            <button
              type="button"
              className="flex justify-between items-center p-5 w-full font-medium text-start"
              onClick={() => handleAccordionClick(index)}
              aria-expanded={activeIndex === index}
              aria-controls={`accordion-body-${index}`}
            >
              <span>{item.title}</span>
              <svg
                data-accordion-icon
                className={`size-4 shrink-0 transform transition-transform ${
                  activeIndex === index ? "rotate-180" : ""
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </h2>
          <div
            id={`accordion-body-${index}`}
            className={`${activeIndex === index ? "" : "hidden"}`}
            aria-labelledby={`accordion-heading-${index}`}
          >
            <div className="p-5">
              <p className="text-slate-400 dark:text-gray-400">
                {item.content}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
