import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { makeGetRequest } from "../../Api/requestMethods";
import {
  productDetailViewByProductId,
  getRatingsByProduct,
} from "../../Api/api";
import { FiUpload } from "react-icons/fi";
import ImageGallery from "./components/ImageGallery";
import "./productDetailedView.css";
import { isValidArrayWithData } from "../../utils/reUsableFun";
import { IndianRupeeSymbol } from "../../global/constants";
import ReviewAndRatings from "../../components/reviewAndRatings/reviewAndRatings";
import ImageUploader from "../../components/imageUploader/imageUploader";
import CommonComponent from "./components/commonComponent";
import { useGlobalProvider } from "../../global/provider";
import { useFormik } from "formik";
import { SessionExistsOrCreate, isValidValue } from "../../utils/reUsableFun";
import { commonComponent_form } from "../../schemas/Forms.js";
import { commonComponent_validation } from "../../schemas/Validations.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth.js";
import { statusSuccess } from "../../global/constants.js";
import useAddToCart from "../../hooks/useAddToCart.js";
import { scrollToTop } from "../../utils/reUsableFun";
const ProductDetailedView = () => {
  const { addToCart, fetchCountCount } = useAddToCart();
  const [isShopNow, setShowEvent] = useState(false);
  const navigate = useNavigate();

  const { user } = useAuth();
  const { setGlobalDs } = useGlobalProvider();
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("cid");
  const product_id = searchParams.get("pid");
  const [dataSource, setDataSource] = useState({
    categoryId: null,
    productsId: null,
    mainImage: "",
    isDescriptionActive: true,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    setValues,
    setFieldError,
    setTouched,
  } = useFormik({
    initialValues: commonComponent_form,
    validationSchema: commonComponent_validation,
    onSubmit: async (values, { setSubmitting }) => {
      await handleProductSubmit();
      setSubmitting(false);
    },
  });



  useEffect(() => {scrollToTop()},[])

  const handleProductSubmit = async (isShowNow) => {
    try {
      const session_id = SessionExistsOrCreate();
      const response = await addToCart(
        user || null,
        session_id,
        product_id,
        values
      );
      if (response && response.status === statusSuccess) {
        const counterResponse = await fetchCountCount(user);
        setGlobalDs((prev) => ({
          ...prev,
          cartCount:
            counterResponse === 0
              ? counterResponse
              : counterResponse?.data?.count,
        }));
        isShopNow ? navigate("/checkout") : toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error, "erorr");
    }
  };

  const fetchQuery = async () =>
    await makeGetRequest(productDetailViewByProductId(category_id, product_id));
  const { data: ProductDataSource } = useQuery({
    queryKey: ["getCategoryDetailsById", category_id, product_id],
    queryFn: () => fetchQuery(),
    enabled: !!category_id && !!product_id,
  });

  const productImages =
    ProductDataSource &&
    ProductDataSource.data &&
    ProductDataSource.data[0] &&
    JSON.parse(ProductDataSource?.data[0]?.products_image);

  const productDetails =
    ProductDataSource &&
    isValidArrayWithData(ProductDataSource.data) &&
    ProductDataSource.data[0];

  const onImageUpload = (base64Image) => {
    setFieldValue("image", base64Image);
  };

  const attributeChangeEvent = (attribute) => {
    try {
      const filterAttributeValues = isValidArrayWithData(
        ProductDataSource?.data[0].category_attributes
      )
        ? ProductDataSource?.data[0].category_attributes?.filter(
            (data) => data.attributeType === attribute
          )
        : [];
      if (isValidArrayWithData(filterAttributeValues)) {
        const { real_price, discount_price } = filterAttributeValues[0];
        setFieldValue("real_price", real_price);
        setFieldValue("discount_price", discount_price);
      }
    } catch (error) {
      // notify
    }
  };

  useEffect(() => {
    attributeChangeEvent(values.attributeType);
  }, [values.attributeType]);


  return (
    <>
      <section className="relative md:py-24 py-16">
        <div className="container relative mt-10">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 items-center">
            {productImages && isValidArrayWithData(productImages) ? (
              <ImageGallery productImages={productImages} />
            ) : null}
            <div className="">
              <h5 className="text-2xl font-semibold">
                {" "}
                {productDetails?.products_name}
              </h5>
              <div className="mt-2">
                <span
                  className="text-slate-400
font-semibold me-1"
                >
                  {IndianRupeeSymbol}{" "}
                  {values.real_price === null
                    ? productDetails?.real_price
                    : values.real_price}{" "}
                  <del className="text-red-600">
                    {IndianRupeeSymbol}{" "}
                    {values.discount_price === null
                      ? productDetails?.fake_price
                      : values.discount_price}
                  </del>
                </span>

                {/* Ratings and feedbacks */}

                <ul
                  className="list-none inline-block
text-orange-400"
                >
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li
                    className="inline text-slate-400
font-semibold"
                  >
                    4.8 (45)
                  </li>
                </ul>
              </div>
              <CommonComponent
                product_id={product_id}
                productDetails={productDetails}
                attributeChangeEvent={attributeChangeEvent}
                handleSubmit={handleSubmit}
                setShowEvent={setShowEvent}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                onImageUpload={onImageUpload}
              />
            </div>
          </div>
          {/* <div className="mt-4">
            <h5 className="text-lg font-semibold">Overview :</h5>
            <p className="text-slate-400 mt-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
              exercitationem, unde molestiae sint quae inventore atque minima
              natus fugiat nihil quisquam voluptates ea omnis. Modi laborum
              soluta tempore unde accusantium.
            </p>
          </div> */}

          <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div className="lg:col-span-3 md:col-span-5">
              <div className="sticky top-20">
                <ul
                  className="flex-column p-6 bg-white
dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li
                    role="presentation"
                    onClick={() =>
                      setDataSource((prev) => ({
                        ...prev,
                        isDescriptionActive: true,
                      }))
                    }
                  >
                    <button
                      className={`px-4 py-2
text-start text-base font-semibold rounded-md w-full
hover:text-orange-500 duration-500 ${
                        dataSource.isDescriptionActive ? "active_button" : ""
                      }`}
                      id="description-tab"
                      data-tabs-target="#description"
                      type="button"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      Description
                    </button>
                  </li>

                  <li
                    role="presentation"
                    onClick={() =>
                      setDataSource((prev) => ({
                        ...prev,
                        isDescriptionActive: false,
                      }))
                    }
                  >
                    <button
                      className={`px-4 py-2
text-start text-base font-semibold rounded-md w-full mt-3
duration-500 ${!dataSource.isDescriptionActive ? "active_button" : ""}`}
                      id="review-tab"
                      data-tabs-target="#review"
                      type="button"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      Review
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="lg:col-span-9 md:col-span-7">
              <div
                id="myTabContent"
                className="p-6 bg-white
dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
              >
                {dataSource.isDescriptionActive ? (
                  <div
                    className=""
                    id="description"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <p className="text-slate-400">
                      {productDetails?.product_description}
                    </p>
                  </div>
                ) : (
                  <ReviewAndRatings
                    product_id={product_id}
                    category_id={category_id}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 mb-6 text-center">
            <h3
              className="font-semibold text-3xl
leading-normal"
            >
              New Arrival Items
            </h3>
          </div>

          <div
            className="grid lg:grid-cols-4 md:grid-cols-3
sm:grid-cols-2 grid-cols-1 gap-6 pt-6"
          >
            <div className="group">
              <div
                className="relative overflow-hidden
shadow dark:shadow-gray-800 group-hover:shadow-lg
group-hover:dark:shadow-gray-800 rounded-md duration-500"
              >
                <img
                  src="assets/images/shop/sweater-t-shirt.jpg"
                  className="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div
                  className="absolute -bottom-20
group-hover:bottom-3 start-3 end-3 duration-500"
                >
                  <a
                    href="shop-cart.html"
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle
duration-500 text-base text-center bg-slate-900 text-white w-full
rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul
                  className="list-none absolute
top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500
space-y-1"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center justify-center tracking-wide
align-middle duration-500 text-center rounded-full bg-white
text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <i data-feather="heart" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="shop-item-detail.html"
                      className="size-10 inline-flex items-center
justify-center tracking-wide align-middle duration-500 text-center
rounded-full bg-white text-slate-900 hover:bg-slate-900
hover:text-white shadow"
                    >
                      <i data-feather="eye" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center
justify-center tracking-wide align-middle duration-500 text-center
rounded-full bg-white text-slate-900 hover:bg-slate-900
hover:text-white shadow"
                    >
                      <i data-feather="bookmark" className="size-4"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <a
                  href="product-detail-one.html"
                  className="hover:text-orange-500 text-lg font-medium"
                >
                  Sweater T-Shirt
                </a>
                <div
                  className="flex justify-between
items-center mt-1"
                >
                  <p>
                    $16.00 <del className="text-slate-400">$21.00</del>
                  </p>
                  <ul
                    className="font-medium
text-amber-400 list-none"
                  >
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="group">
              <div
                className="relative overflow-hidden
shadow dark:shadow-gray-800 group-hover:shadow-lg
group-hover:dark:shadow-gray-800 rounded-md duration-500"
              >
                <img
                  src="assets/images/shop/trendy-t-shirt2.jpg"
                  className="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div
                  className="absolute -bottom-20
group-hover:bottom-3 start-3 end-3 duration-500"
                >
                  <a
                    href="shop-cart.html"
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle
duration-500 text-base text-center bg-slate-900 text-white w-full
rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul
                  className="list-none absolute
top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500
space-y-1"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center justify-center tracking-wide
align-middle duration-500 text-center rounded-full bg-white
text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <i data-feather="heart" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="shop-item-detail.html"
                      className="size-10 inline-flex items-center
justify-center tracking-wide align-middle duration-500 text-center
rounded-full bg-white text-slate-900 hover:bg-slate-900
hover:text-white shadow"
                    >
                      <i data-feather="eye" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center
justify-center tracking-wide align-middle duration-500 text-center
rounded-full bg-white text-slate-900 hover:bg-slate-900
hover:text-white shadow"
                    >
                      <i data-feather="bookmark" className="size-4"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <a
                  href="product-detail-one.html"
                  className="hover:text-orange-500 text-lg font-medium"
                >
                  Trendy T-shirt
                </a>
                <div
                  className="flex justify-between
items-center mt-1"
                >
                  <p>
                    $16.00 <del className="text-slate-400">$21.00</del>
                  </p>
                  <ul
                    className="font-medium
text-amber-400 list-none"
                  >
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="group">
              <div
                className="relative overflow-hidden
shadow dark:shadow-gray-800 group-hover:shadow-lg
group-hover:dark:shadow-gray-800 rounded-md duration-500"
              >
                <img
                  src="assets/images/shop/men-white-espadrilles-slip-shoes.jpg"
                  className="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div
                  className="absolute -bottom-20
group-hover:bottom-3 start-3 end-3 duration-500"
                >
                  <a
                    href="shop-cart.html"
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle
duration-500 text-base text-center bg-slate-900 text-white w-full
rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul
                  className="list-none absolute
top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500
space-y-1"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center justify-center tracking-wide
align-middle duration-500 text-center rounded-full bg-white
text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <i data-feather="heart" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="shop-item-detail.html"
                      className="size-10 inline-flex items-center
justify-center tracking-wide align-middle duration-500 text-center
rounded-full bg-white text-slate-900 hover:bg-slate-900
hover:text-white shadow"
                    >
                      <i data-feather="eye" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center
justify-center tracking-wide align-middle duration-500 text-center
rounded-full bg-white text-slate-900 hover:bg-slate-900
hover:text-white shadow"
                    >
                      <i data-feather="bookmark" className="size-4"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <a
                  href="product-detail-one.html"
                  className="hover:text-orange-500 text-lg font-medium"
                >
                  Mens White Slip Shoes
                </a>
                <div
                  className="flex justify-between
items-center mt-1"
                >
                  <p>
                    $16.00 <del className="text-slate-400">$21.00</del>
                  </p>
                  <ul
                    className="font-medium
text-amber-400 list-none"
                  >
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="group">
              <div
                className="relative overflow-hidden
shadow dark:shadow-gray-800 group-hover:shadow-lg
group-hover:dark:shadow-gray-800 rounded-md duration-500"
              >
                <img
                  src="assets/images/shop/luxurious-bag.jpg"
                  className="group-hover:scale-110 duration-500"
                  alt=""
                />

                <div
                  className="absolute -bottom-20
group-hover:bottom-3 start-3 end-3 duration-500"
                >
                  <a
                    href="shop-cart.html"
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle
duration-500 text-base text-center bg-slate-900 text-white w-full
rounded-md"
                  >
                    Add to Cart
                  </a>
                </div>

                <ul
                  className="list-none absolute
top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500
space-y-1"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center justify-center tracking-wide
                                        align-middle duration-500 text-center rounded-full bg-white
                                        text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <i data-feather="heart" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="shop-item-detail.html"
                      className="size-10 inline-flex items-center
                                        justify-center tracking-wide align-middle duration-500 text-center
                                        rounded-full bg-white text-slate-900 hover:bg-slate-900
                                        hover:text-white shadow"
                    >
                      <i data-feather="eye" className="size-4"></i>
                    </a>
                  </li>
                  <li className="mt-1">
                    <a
                      href="javascript:void(0)"
                      className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                      <i data-feather="bookmark" className="size-4"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <a
                  href="product-detail-one.html"
                  className="hover:text-orange-500 text-lg font-medium"
                >
                  Ladies Luxurious Bag
                </a>
                <div className="flex justify-between items-center mt-1">
                  <p>
                    $16.00 <del className="text-slate-400">$21.00</del>
                  </p>
                  <ul className="font-medium text-amber-400 list-none">
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                    <li className="inline">
                      <i className="mdi mdi-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetailedView;
