import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./whatsApp.css";

const WhatsApp = () => {
  return (
    <a
      href="https://wa.me/919677242722?text=Hello! I'm interested in your products. Please provide information about:%0A%0A1. Photo Frames:%0A   • Custom sizes available%0A  • Types (LED/Non-LED/Acrylic)%0A   • Frame materials and designs%0A   • Bulk order options%0A%0A2. Industrial Sign Boards:%0A  • Customization options%0A   • Installation services%0A%0A3. Other Products:%0A   • Name boards%0A   • Display solutions%0A   • Corporate signage%0A%0A4. Additional Details:%0A   • Pricing%0A   • Production timeline%0A  Thank you!"
      class="whatsapp_float"
      style={{display:'flex', justifyContent:"center", alignItems:"center"}}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp />
    </a>
  );
};

export default WhatsApp;
