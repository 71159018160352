import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <section class="relative md:py-24 py-16">
        <div class="container relative">
        <h1 style={{fontSize:"40px"}}><b>Privacy policy:</b>
        </h1>
          <p>Privacy Policy of Planet56 and Its Associated Apps
          </p>
          <p>Your Privacy Matters to Us
          </p>
          <p>At Planet56, we prioritize your privacy and take it very seriously. Protecting your online privacy is not only a business priority but also a matter of
            principle. We proudly present our clear and straightforward privacy and security policy. Rest assured, we do not monetize your data by selling it
            to third parties. That is NOT our business model.
          </p>
          <p>We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent, and we only use your
            information as described in this Privacy Policy. Safeguarding your privacy is an essential community value for us. Your information is one of our
            most valued assets, and we ensure it is stored and processed on secure servers, protected by both physical and technological safeguards. We
            also work with third-party verifcation services to validate our privacy standards. If you disagree with your information being processed in this
            way, please refrain from using our Site.</p>
          <p>We collect "personal" information only when you choose to provide it. For instance, if you make a purchase, we may collect your name, mailing
            address, telephone number, and email address. If you sign up for a newsletter, we collect your email address. When you take advantage of
            special services, additional personal information may be collected. We use this information for internal purposes, such as processing orders
            and keeping you informed</p>
          <p>We do not, under any circumstances, rent, trade, or share your personal information with other companies for their marketing purposes without
            your consent. However, we may disclose your information to third parties who submit a legally compliant request for its disclosure. Other than
            such cases, we will not share your name, address, or any other personally identifable information with third parties without your consent. We
            may also collect general demographic and non-identifable information that does not personally identify you. This type of information is not
            linked to your personal details.</p>
          <p>If you no longer wish to receive email updates and other marketing information from us, or if you would like us to remove any information we
            have collected, please send an email to info@planet56.in.
          </p>
          <p>We use cookies and similar technologies, such as pixel tags and clear gifs, to store certain types of information whenever you visit pages on
            our website. Cookies enable the site to recognize information you have agreed to provide and help us determine which parts of the site may be
            most relevant for your needs. We may also use cookies to display advertisements, either served by us or by third parties on our behalf. These
            cookies do not contain any personal information</p>
          <p>The choice to accept cookies is yours. By default, most browsers are set to accept cookies, but you can modify your browser settings if you
            prefer to decline them. However, doing so may impact your experience on our site and could render some features nonfunctional. We
            recommend keeping cookies enabled to ensure full functionality.
          </p>
          <p>We also work with third-party service providers to display ads on our behalf across the Internet and occasionally on our site. These providers
            may gather anonymous information about your visits to our website and your interactions with our content. This information may also be used
            to tailor advertisements for you on other sites. This anonymous information is collected using pixel tags, a standard technology widely used
            across the web. No personally identifable information is collected or used in this process. The advertisers do not have access to your name,
            phone number, address, email, or any personal identifers.</p>
        </div>

      </section>
    </>
  );
};

export default PrivacyPolicy;
