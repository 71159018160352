import React from "react";
import forgotPasswordImage from "../../assets/static/LogIN/ForgotPassword.png";
import { IoMdClose } from "react-icons/io";

const ForgotPasswordModal = ({ handleSwitchPopup, handleClose }) => {
  return (
    <div class="grid grid-cols-1">
      <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
      <IoMdClose style={{position: "absolute", top:"20px", right:"20px", fontSize:"24px", cursor : "pointer"}}  onClick={handleClose}/>
        <div class="d-flex items-center">
          <div class="relative w-auto d-none d-md-block">
            <img
              class="h-full object-cover LoginImg"
              src={forgotPasswordImage}
              alt=""
              style={{width:"500px",height:"auto"}}
            />
          </div>
          <div class="p-8 lg:px-20">
            <p class="text-slate-400 mb-6">
              Please enter your email address. You will receive a link to create
              a new password via email.
            </p>
            <div class="grid grid-cols-1">
              <div class="mb-4">
                <label class="font-semibold" for="LoginEmail">
                  Email Address:
                </label>
                <input
                  id="LoginEmail"
                  type="email"
                  class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                  placeholder="name@example.com"
                />
              </div>

              <div class="mb-4">
                <input
                  type="submit"
                  class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
                  value="Send"
                />
              </div>

              <div class="text-center">
                <span class="text-slate-400 me-2">
                  Remember your password ?{" "}
                </span>
                <button onClick={() => handleSwitchPopup("login")}>
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
