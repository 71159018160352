import React from "react";
import Accordion from "./accordion";
import { orderPlaceMockData, multipleOrder } from "../../global/constants";
import FeatherIcon from "feather-icons-react";

const HelpCenter = () => {
  return (
    <section className="relative md:py-24 py-16">
      <div className="container relative">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            Find The Help You Need
          </h3>
        </div>

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FeatherIcon icon="phone" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Phone</h5>
                <p className="text-slate-400 mt-3">
                  <a href="tel:9677242722" style={{ color: "#8cc33f" }}>
                    Planet56 - 9677242722
                  </a>{" "}
                  <br />
                  (Mon - Sun: 10:00 AM - 7.00 PM)-
                </p>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FeatherIcon icon="mail" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Email</h5>

                <div className="mt-5">
                  <a
                    href="mailto:contact@example.com"
                    className="text-orange-500 font-medium"
                  >
                    info@planet56.in
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FeatherIcon icon="map-pin" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-lg font-semibold">Location</h5>
                <p className="text-slate-400 mt-3">
                  1A PILLAYAR KOIL STREET POONAMALLEE,CHENNAI-600056
                </p>

                <div className="mt-5">
                  <a
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    data-type="iframe"
                    className="video-play-icon read-more lightbox text-orange-500 font-medium"
                  >
                    View on Google map
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
        <Accordion data={orderPlaceMockData} />
        <Accordion data={multipleOrder} />

        </div>


      </div>
    </section>
  );
};

export default HelpCenter;
