import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeGetRequest } from "../../Api/requestMethods";
import { productDetailsById } from "../../Api/api";
import { useQuery } from "@tanstack/react-query";
import { productImagePath } from "../../config/config";
import { useGlobalProvider } from "../../global/provider";
import useWishlist from "../../hooks/useWishList";
import Pagination from "../../components/pagination/pagination";
import { isValidArrayWithData } from "../../utils/reUsableFun";
import { sortByType } from "../../global/constants";
import { sortArrayByProperty } from "../../utils/reUsableFun";
import { encryptData } from "../../utils/cryptoUtils";
import ReviewAndRatings from "../../components/reviewAndRatings/reviewAndRatings";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { globalDs, setGlobalDs } = useGlobalProvider();
  const { addToWishlist, removeFromWishlist, updateWishlist } = useWishlist();
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("id");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const productsPerPage = 9;
  const [dataSource, setDataSource] = useState({
    categoryId: null,
    sortDs: sortByType,
    productDs: "",
  });

  const fetchQuery = () => {
    return makeGetRequest(
      productDetailsById(category_id, currentPage, productsPerPage)
    );
  };

  const {
    data: ProductDataSource,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getCategoryDetailsById", category_id, currentPage],
    queryFn: () => fetchQuery(),
    enabled: !!category_id,
  });

  useEffect(() => {
    try {
      if (isValidArrayWithData(ProductDataSource?.data?.products)) {
        const uniqueArray = [];
        ProductDataSource?.data?.products.forEach((data) => {
          const checkExists = uniqueArray.some(
            (ele) => ele.name === data.discount_highlights
          );
          if (
            !checkExists &&
            !dataSource.sortDs.some(
              (ele) => ele.name === data.discount_highlights
            )
          ) {
            uniqueArray.push({
              name: data.discount_highlights,
              value: data.discount_highlights,
            });
          }
        });
        if (uniqueArray.length > 0) {
          setDataSource((prev) => ({
            ...prev,
            sortDs: [...prev.sortDs, ...uniqueArray],
          }));
        }
        setTotalPages(ProductDataSource.data.totalPages);
        const sortedArray = sortArrayByProperty(
          ProductDataSource?.data?.products,
          "products_name",
          "str_asc",
          []
        );
        setDataSource((prev) => ({ ...prev, productDs: sortedArray }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [ProductDataSource]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const sortOnChange = (e) => {
    try {
      const selectedSort = JSON.parse(e.target.value);
      const { name, prop, value } = selectedSort;
      const sortedArray = sortArrayByProperty(
        ProductDataSource?.data?.products,
        prop,
        name,
        value
      );
      setDataSource((prev) => ({ ...prev, productDs: sortedArray }));
    } catch (error) {
      console.error(error);
    }
  };

  const productDetailEvent = (categoryId, productId) => {
    navigate(
      `/ProductDetailedView?cid=${encryptData(categoryId)}&pid=${encryptData(
        productId
      )}`
    );
  };

  const addToWishlisting = (data, isWishlistItem) => {
    isWishlistItem
      ? removeFromWishlist(data)
      : addToWishlist(data, isWishlistItem);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  // Skeleton loading elements
  const skeletonLoader = (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
      {Array(9)
        .fill()
        .map((_, index) => (
          <div key={index} className="group">
            <Skeleton height={250} />
            <div className="mt-4">
              <Skeleton height={20} width={150} />
              <Skeleton height={20} width={100} />
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 sm:grid-cols-2 grid-cols-1 gap-6">
            <div className="lg:col-span-9 md:col-span-8">
              <div className="md:flex justify-between items-center mb-6">
                <span className="font-semibold">
                  Showing 1 -{" "}
                  {ProductDataSource && ProductDataSource?.data?.totalCount} of{" "}
                  {ProductDataSource &&
                    ProductDataSource?.data?.products.length}{" "}
                  items
                </span>

                <div className="md:flex items-center">
                  <label className="font-semibold md:me-2">Sort by:</label>
                  <select
                    className="form-select form-input md:w-36 w-full md:mt-0 mt-1 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                    onChange={sortOnChange}
                  >
                    {dataSource &&
                      isValidArrayWithData(dataSource?.sortDs) &&
                      dataSource?.sortDs.map((data, index) => (
                        <option key={index} value={JSON.stringify(data)}>
                          {data.value}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="lg:col-span-9 md:col-span-8">
                {isLoading ? (
                  skeletonLoader // Render skeleton loader when loading
                ) : (
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                    {dataSource &&
                      dataSource?.productDs?.length > 0 &&
                      dataSource?.productDs.map((product, index) => {
                        const images = JSON.parse(product.products_image);
                        const firstImage = images[0];
                        const matchingWishlistItem =
                          globalDs.wishlistDataSource &&
                          globalDs.wishlistDataSource.length > 0 &&
                          globalDs.wishlistDataSource.find(
                            (wishlistItem) =>
                              wishlistItem.products_id === product.products_id
                          );
                        const isWishlistItem = !!matchingWishlistItem;

                        return (
                          <div
                            key={product.products_id}
                            className="group"
                            onClick={() =>
                              productDetailEvent(
                                product.category_id,
                                product.products_id
                              )
                            }
                          >
                            <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                              <img
                                src={`${productImagePath}${firstImage}`}
                                className="group-hover:scale-110 duration-500"
                                alt={product.products_name}
                              />
                              <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                                <li>
                                  <div
                                    className={`size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full ${
                                      isWishlistItem
                                        ? "bg-green-500 text-white hover:bg-green-600"
                                        : "bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                                    }`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      addToWishlisting(product, isWishlistItem);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-heart size-4"
                                      style={{
                                        color: isWishlistItem
                                          ? "#8cc33f"
                                          : "red",
                                      }}
                                    >
                                      <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                                    </svg>
                                  </div>
                                </li>
                              </ul>

                              <ul className="list-none absolute top-[10px] start-4">
                                <li>
                                  <button className="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 d-flex align-items-center">
                                    {product?.discount_percentage}% Off
                                  </button>
                                </li>
                              </ul>
                            </div>

                            <div className="mt-4">
                              <button className="hover:text-orange-500 text-lg font-medium">
                                {product.products_name}
                              </button>
                              <div className="flex justify-between items-center mt-1">
                                <p>
                                  ₹ {product?.real_price}{" "}
                                  <del className="text-slate-400">
                                    ₹ {product?.fake_price}
                                  </del>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}

                {!isLoading && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
