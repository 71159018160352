// status
const statusSuccess = 200;
const success = "success";

// Error messages

const wishlistDeleted = "wishlist deleted";
const wishlistRemoved = `wishlist removed`;
const wishlistAdded = "wishlist added";

const requiredField = `This field is required`;
const invalidEmail = `Invalid Email`;
const passwordLength = `Password must be least 6 characters`;
const nameLengthError = `min 3 characters required!`;
const somethingWentWrong = `Something went wrong!`;
const IndianRupeeSymbol = `₹`;
const mobileLength = "invalid number";

const valueOne = "1";
const valueZero = "0";

const valueHome = "Home";
const valueWork = "Work";

export const sortByType = [
  { name: "str_asc", value: "Alpha A-Z", prop: "products_name" },
  { name: "str_des", value: "Alpha Z-A", prop: "products_name" },
  { name: "num_asc", value: "Price Low-High", prop: "real_price" },
  { name: "num_des", value: "Price High-Low", prop: "real_price" },
];

export const ratingsMockData = [
  { value: 5, name: "😍", title: "Lovely" },
  { value: 4, name: " 😉", title: "happy" },
  { value: 3, name: "😊", title: "good" },
  { value: 2, name: "☹️", title: "worried" },
  { value: 1, name: "😖", title: "sad" },
];

const indianStates = [
  { code: "AN", name: "Andaman and Nicobar Islands" },
  { code: "AP", name: "Andhra Pradesh" },
  { code: "AR", name: "Arunachal Pradesh" },
  { code: "AS", name: "Assam" },
  { code: "BR", name: "Bihar" },
  { code: "CG", name: "Chandigarh" },
  { code: "CH", name: "Chhattisgarh" },
  { code: "DH", name: "Dadra and Nagar Haveli" },
  { code: "DD", name: "Daman and Diu" },
  { code: "DL", name: "Delhi" },
  { code: "GA", name: "Goa" },
  { code: "GJ", name: "Gujarat" },
  { code: "HR", name: "Haryana" },
  { code: "HP", name: "Himachal Pradesh" },
  { code: "JK", name: "Jammu and Kashmir" },
  { code: "JH", name: "Jharkhand" },
  { code: "KA", name: "Karnataka" },
  { code: "KL", name: "Kerala" },
  { code: "LD", name: "Lakshadweep" },
  { code: "MP", name: "Madhya Pradesh" },
  { code: "MH", name: "Maharashtra" },
  { code: "MN", name: "Manipur" },
  { code: "ML", name: "Meghalaya" },
  { code: "MZ", name: "Mizoram" },
  { code: "NL", name: "Nagaland" },
  { code: "OR", name: "Odisha" },
  { code: "PY", name: "Puducherry" },
  { code: "PB", name: "Punjab" },
  { code: "RJ", name: "Rajasthan" },
  { code: "SK", name: "Sikkim" },
  { code: "TN", name: "Tamil Nadu" },
  { code: "TS", name: "Telangana" },
  { code: "TR", name: "Tripura" },
  { code: "UK", name: "Uttarakhand" },
  { code: "UP", name: "Uttar Pradesh" },
  { code: "WB", name: "West Bengal" },
];

const orderPlaceMockData = [
  {
    title: 'How do I place an order?',
    content: 'To order printed products from our website, simply browse through our product categories and choose the items that meet your needs. Once you\'ve selected a product, customize it by choosing the desired size, material, and other specifications. You can then upload your design files directly on the product page. After making your selections, add the products to your cart and proceed to checkout. Finally, complete your order by entering your shipping details and selecting your preferred payment method. Enjoy a seamless shopping experience with high-quality printed products delivered right to your door.'
  },
  {
    title: 'Will I get a design proof / preview before placing the order?',
    content: 'Design previews or mockups will only be provided for certain product categories or when approval is necessary due to design changes or modifications. If you have any special requests or require assistance, please don\'t hesitate to contact us.'
  },
  {
    title: 'Should I place an order only via online?',
    content: 'As an e-commerce platform, our ordering process requires the online placement of prepaid orders, where payment is made in advance during the checkout process.'
  },
  {
    title: 'I am placing a re-order and the price seems to be higher than the previous time I ordered',
    content: 'Our prices are subject to change based on current market trends, fluctuations in raw material costs, and logistics expenses. While these factors may affect pricing, we are committed to transparency and ensuring our customers receive the best value for their purchases. Rest assured, we continuously monitor the market to offer the most competitive prices without ever compromising on the quality of our products. Our goal is to provide you with high-quality items at fair prices, regardless of market conditions.'
  }
];


const multipleOrder = [
  {
    title: 'Can I place multiple orders for a single product?',
    content: 'You can place multiple orders for a single product if needed. However, if you intend to use the same design for all the items, we recommend placing a single order for convenience. This simplifies the process and ensures a smoother experience.'
  },
  {
    title: 'Can I share multiple images / designs in an order?',
    content: 'To avoid technical issues and ensure accurate printing quantities, we only allow one design per order. If you have multiple designs, we recommend placing separate orders for each design. For bulk orders with multiple designs, feel free to reach out to our support team at info@planet56.in for further assistance.'
  },
  {
    title: 'How do I re-order? Is it possible to re-order with design changes?',
    content: 'Would you like to place the same order again? We\'ve made it simple! After completing your initial order, you can easily reorder the same products. Just go to your account, select "My Orders," and click on "Re-order." Additionally, while reordering, you have the option to modify the existing design directly on the canvas. If the re-order option isn\'t available, it may be due to changes or discontinuation of the product. In such cases, please reach out to our customer service team to explore alternative options.'
  }
];



export {
  orderPlaceMockData,
  multipleOrder,
  mobileLength,
  valueHome,
  valueWork,
  valueOne,
  valueZero,
  success,
  wishlistAdded,
  wishlistRemoved,
  statusSuccess,
  passwordLength,
  invalidEmail,
  requiredField,
  nameLengthError,
  somethingWentWrong,
  IndianRupeeSymbol,
  wishlistDeleted,
  indianStates,
};
