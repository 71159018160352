import React from 'react'
import { Link } from 'react-router-dom'
import Forgot from "../../assets/static/LogIN/ForgotPassword.png"

const ForgotPassword = () => {
  return (
    <>
      <section class="md:h-screen py-36 flex items-center bg-orange-500/10 dark:bg-orange-500/20 bg-[url('../../assets/images/hero/bg-shape.png')] bg-center bg-no-repeat bg-cover">
        <div class="container relative">
          <div class="grid grid-cols-1">
            <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
              <div class="d-flex items-center">
                <div class="relative  w-auto">
                  <img class="lg:h-full h-full object-cover md:h-[34rem] LoginImg"  src={Forgot} alt="" />
                </div>
                <div class="p-8 lg:px-20">
                    <p class="text-slate-400 mb-6">Please enter your email address. You will receive a link to create a new password via email.</p>
                    <div class="grid grid-cols-1">
                      <div class="mb-4">
                        <label class="font-semibold" for="LoginEmail">Email Address:</label>
                        <input id="LoginEmail" type="email" class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="name@example.com" />
                      </div>

                      <div class="mb-4">
                        <input type="submit" class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full" value="Send" />
                      </div>

                      <div class="text-center">
                        <span class="text-slate-400 me-2">Remember your password ? </span><Link to={"/login"} class="text-black dark:text-white font-bold inline-block">Sign in</Link>
                      </div>
                    </div>
        


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default ForgotPassword






