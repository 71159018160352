import React from "react";

const CancellationandRefunds = () => {
  return (
    <>
      <section class="relative md:py-24 py-16">
        <div class="container relative">
        <h1 style={{fontSize:"40px"}}><b>Cancellation, Refund, and Return Policy
        Refund Policy:</b>
          </h1>
          <p>If your payment is deducted but your order is canceled (due to personal request, design issues, etc.), a refund will be processed within 7-10
            working days.</p>
          <p>If you cancel your order to place a different order, the amount will be credited as a "Re-Order Token" to your account, which can be used for
            future purchases.
            Return Policy</p>
          <p>Returns are only accepted if the package is damaged or if the incorrect product is sent. This should be noted to the carrier upon accepting the
            delivery.</p>
          <p>Delayed Delivery and Compensation:
          </p>
          <p>In cases of delays due to severe weather, government restrictions, or courier issues, no refund or compensation will be available. We will,
            however, work to resolve any delivery issues promptly.
            Feedback on Shipment:
          </p>
          <p>If the product appears tampered with, damaged, or faulty upon receipt, please provide feedback to ensure prompt closure of the claim.
            For further assistance, contact Planet56 at info@planet56.in.</p>
        </div>
      </section>
    </>
  );
};

export default CancellationandRefunds;
