import React, { useState } from "react";
import { useFormik } from "formik";
import { Login_form } from "../../schemas/Forms";
import { login_validation } from "../../schemas/Validations";
import { postFetch } from "../../utils/reUsableFun";
import { login } from "../../Api/api";
import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import Modal from 'react-bootstrap/Modal';


const LoginForm = () => {
  const { createToken } = useAuth()
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: Login_form,
    validationSchema: login_validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await loginMutation.mutateAsync(values);
      setSubmitting(false);
    },
  });

  const loginMutation = useMutation({
    mutationFn: async (loginData) => {
      const response = await postFetch(login, loginData);
      return response;
    },
    onSuccess: async (data) => {
      await createToken(data);
      toast.success(data.message);
    },
    onError: async (error) => {
      const errorMessage = error?.response?.data?.message
      toast.error(errorMessage);
    },
  });



  return (
    <>
      <section class="md:h-screen py-36 flex items-center bg-orange-500/10 dark:bg-orange-500/20 bg-[url('../../assets/images/hero/bg-shape.png')] bg-center bg-no-repeat bg-cover">
        <div class="container relative">
          <div class="grid grid-cols-1">
            <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
              <div class="d-flex items-center">
                <div class="relative w-auto d-none d-lg-block">
                  <img class="h-full object-cover LoginImg" src="https://gratisography.com/wp-content/uploads/2024/01/gratisography-cyber-kitty-800x525.jpg" alt="" />
                </div>

                <div class="p-8 lg:px-20">


                  <form class="text-start lg:py-20 py-8">
                    <div class="grid grid-cols-1">
                      <div class="mb-4">
                        <label class="font-semibold" for="LoginEmail">Email Address:</label>
                        <input
                          id="LoginEmail"
                          type="text"
                          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="name@example.com"
                          value={values.email}
                          onChange={(e) => handleChange({ target: { name: "email", value: e.target.value } })}
                          onBlur={(e) => handleBlur({ target: { name: "email", value: e.target.value } })}
                        />
                        {touched.email && errors.email ? (
                          <div className="fieldRequiredError text-red-500 text-sm">{errors.email}</div>
                        ) : null}

                      </div>

                      <div class="mb-4">
                        <label class="font-semibold" for="LoginPassword">Password:</label>
                        <input
                          id="LoginPassword"
                          type="password"
                          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="Password"
                          value={values.password}
                          onChange={(e) => handleChange({ target: { name: "password", value: e.target.value } })}
                          onBlur={(e) => handleBlur({ target: { name: "password", value: e.target.value } })}
                        />
                        {touched.password && errors.password ? (
                          <div className="fieldRequiredError text-red-500 text-sm">{errors.password}</div>
                        ) : null}
                      </div>

                      <div class="flex justify-between mb-4">
                        <div class="flex items-center mb-0">
                        </div>
                        <p class="text-slate-400 mb-0"><Link to="/forgotPassword" class="text-slate-400">Forgot password ?</Link></p>
                      </div>

                      <div class="mb-4">
                        <button class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full" value="Login / Sign in" onClick={handleSubmit} disabled={isSubmitting}>Login / Sign in</button>
                      </div>

                      <div class="text-center">
                        <span class="text-slate-400 me-2">Don't have an account ?</span> <Link to={"/confirmEmail"} class="text-black dark:text-white font-bold inline-block">Sign Up</Link>
                      </div>
                    </div>
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </section>
    </>
  );
};

export default LoginForm;
