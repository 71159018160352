// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { adminCategoryImagePath } from "../../../config/config";
// import left from "../../../assets/static/icons/left.svg";
// import right from "../../../assets/static/icons/right.svg";
// import "./landingCarosel.css";

// let slidesToShow = 5;

// function SampleNextArrow(props) {
//   const { className, style, onClick, currentSlide } = props;
//   return (
//     <>
//       {currentSlide !== 0 && (
//         <div
//           className={`${className} custom-arrow2 next-arrow right`} // Corrected template literal usage
//           style={{ ...style, display: "block" }}
//           onClick={onClick}
//         >
//           <img src={right} alt="Next" />
//         </div>
//       )}
//     </>
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick, slideCount, currentSlide } = props;
//   return (
//     <>
//       {currentSlide !== slideCount - slidesToShow && (
//         <div
//           className={`${className} custom-arrow2 prev-arrow left`} // Corrected template literal usage
//           style={{ ...style, display: "block" }}
//           onClick={onClick}
//         >
//           <img src={left} alt="Previous" />
//         </div>
//       )}
//     </>
//   );
// }

// const ProductSlider = ({ categoryDataSource, CategoryEvent }) => {
//   const carouselProperties = {
//     prevArrow: <SamplePrevArrow />,
//     nextArrow: <SampleNextArrow />,
//     slidesToShow: slidesToShow,
//     slidesToScroll: 2,
//     infinite: false,
//     responsive: [
//       {
//         breakpoint: 426,
//         settings: {
//           slidesToShow: 1,
//           centerMode: false,
//         },
//       },
//       {
//         breakpoint: 769,
//         settings: {
//           slidesToShow: 3,
//           centerMode: false,
//         },
//       },
//       {
//         breakpoint: 1025,
//         settings: {
//           slidesToShow: 4,
//           centerMode: false,
//           slidesToScroll: 2,
//         },
//       },
//     ],
//   };

//   const [width, setWidth] = useState(window.innerWidth);
//   const updateWidth = () => {
//     setWidth(window.innerWidth);
//   };

//   useEffect(() => {
//     window.addEventListener("resize", updateWidth);
//     return () => window.removeEventListener("resize", updateWidth);
//   }, []);

//   if (width <= 426) {
//     slidesToShow = 1;
//   } else if (width > 426 && width <= 769) {
//     slidesToShow = 3;
//   } else if (width > 769 && width <= 1025) {
//     slidesToShow = 4;
//   } else {
//     slidesToShow = 5;
//   }

//   return (
//     <div className="container relative md:mt-12 mt-12">
//       <div className="grid grid-cols-1 justify-center text-center mb-6">
//         <h5 className="font-semibold text-3xl leading-normal mb-4">
//           New Arrival Products
//         </h5>
//       </div>
//       <Slider dots={false} {...carouselProperties}>
//         {categoryDataSource &&
//           categoryDataSource.data.map((data) => (
//             <div
//               key={data.category_id}
//               className="item flex-none w-full sm:w-1/2 md:w-1/4 lg:w-1/6 mx-2"
//               onClick={() => CategoryEvent(data)}
//             >
//               <img
//                 src={`${adminCategoryImagePath}${data.category_image}`} // Corrected template literal usage
//                 className="mx-auto ProductHeight rounded-full shadow dark:shadow-gray-800 mx-auto"
//                 alt={data.category_name}
//                 style={{ width: "200px", height: "200px" }}
//               />
//               <span className="text-xl font-medium mt-3 block text-center">
//                 {data.category_name}
//               </span>
//             </div>
//           ))}
//       </Slider>
//     </div>
//   );
// };

// export default ProductSlider;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./landingCarosel.css";
import left from "../../../assets/static/icons/ProductLeft.png";
import right from "../../../assets/static/icons/ProductRight.png";
import { useState } from "react";
import { useEffect } from "react";
import { adminCategoryImagePath } from "../../../config/config";

let slidesToShow = 4;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <img src={left} style={{ color: "blue", fontSize: "30px" }}></img>
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  console.log(props);
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <img src={right} style={{ color: "blue", fontSize: "30px" }}></img>
        </div>
      )}
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

const ProductSlider = ({ categoryDataSource, CategoryEvent }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }

  return (
    <div className="container relative md:mt-12 mt-12">
      <div className="grid grid-cols-1 justify-center text-center mb-6">
        <h5 className="font-semibold text-3xl leading-normal mb-4">
          New Arrival Products
        </h5>
      </div>
      <Slider {...carouselProperties}>
        {categoryDataSource &&
          categoryDataSource.data.map((item) => (
            <Card item={item} CategoryEvent={CategoryEvent} />
          ))}
      </Slider>
    </div>
  );
};

const Card = ({ item, CategoryEvent }) => {
  return (
    <div
      key={item.category_id}
      className="item flex-none w-full sm:w-1/2 md:w-1/4 lg:w-1/6 mx-2"
      onClick={() => CategoryEvent(item)}
    >
      <img
        src={`${adminCategoryImagePath}${item.category_image}`}
        className="mx-auto ProductHeight rounded-full shadow dark:shadow-gray-800 mx-auto"
        alt={item.category_name}
        style={{ width: "200px", height: "200px" }}
      />
      <span className="text-xl font-medium mt-3 block text-center">
        {item.category_name}
      </span>
    </div>
  );
};

export default ProductSlider;
