import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { statusSuccess } from "../../../global/constants";
import { deleteWishlist } from "../../../Api/api";
import { makeDeleteRequest } from "../../../Api/requestMethods";
import { useAuth } from "../../../contexts/auth";
import { productImagePath } from "../../../config/config";
import {
  IndianRupeeSymbol,
  wishlistDeleted,
  somethingWentWrong,
} from "../../../global/constants";
import { useGlobalProvider } from "../../../global/provider";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { scrollToTop } from "../../../utils/reUsableFun";
import "react-loading-skeleton/dist/skeleton.css";

const Wishlist = () => {
  const { globalDs, globalSetSettings } = useGlobalProvider();
  const { user } = useAuth();

  const deleteWishList = async (data) => {
    try {
      const response = await makeDeleteRequest(
        deleteWishlist(user.id, data.products_id)
      );
      if (response && response.status === statusSuccess) {
        globalSetSettings((prev) => ({
          ...prev,
          whislistDeleteTrigger: prev.whislistDeleteTrigger + 1,
        }));
        toast.success(wishlistDeleted);
      } else {
        toast.error(somethingWentWrong);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => scrollToTop());

  return (
    <>
      {globalDs?.wishlistDataSource?.length === 0 ? (
        <p>Whishlist is empty!</p>
      ) : (
        <>
          <h5 class="text-lg font-semibold my-6">
            My Wishlist ({globalDs?.wishlistDataSource?.length || 0})
          </h5>
          <div class="rounded-md shadow dark:shadow-gray-800 p-6">
            <ul>
              {globalDs.isWishlistLoading
                ? Array(6)
                    .fill()
                    .map((_, index) => (
                      <li
                        class={`flex justify-between items-center py-6 ${
                          index > 0
                            ? "border-t border-gray-100 dark:border-gray-700"
                            : ""
                        }`}
                        key={index}
                      >
                        <div class="flex items-center">
                          <Skeleton height={64} width={64} />
                          <div class="ms-4">
                            <Skeleton width={200} height={20} />
                            <Skeleton width={100} height={20} />
                          </div>
                        </div>
                        <Skeleton width={30} height={30} />
                      </li>
                    ))
                : // Render the actual wishlist once the data is loaded
                  globalDs.wishlistDataSource &&
                  globalDs?.wishlistDataSource?.map((data, index) => (
                    <li
                      class={`flex justify-between items-center py-6 ${
                        index > 0
                          ? "border-t border-gray-100 dark:border-gray-700"
                          : ""
                      }`}
                      key={index}
                    >
                      <div class="flex items-center">
                        <img
                          src={`${productImagePath}${data?.products_image}`}
                          class="rounded shadow dark:shadow-gray-800 w-16"
                          alt=""
                        />
                        <div class="ms-4">
                          <a
                            href=""
                            class="font-semibold hover:text-orange-500"
                          >
                            {data?.products_name}
                          </a>
                          <p class="text-green-600 text-sm mt-1">
                            {IndianRupeeSymbol}
                            {data?.real_price}{" "}
                            <del class="text-red-600">
                              {IndianRupeeSymbol}
                              {data?.fake_price}
                            </del>
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 hover:bg-red-600 text-red-600 hover:text-white rounded-full"
                          onClick={() => deleteWishList(data)}
                        >
                          <FeatherIcon icon="trash-2" class="h-4 w-4" />
                        </button>
                      </div>
                    </li>
                  ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default Wishlist;
