import { useCallback } from "react";
import { makePostRequest, makeDeleteRequest } from "../Api/requestMethods";
import { somethingWentWrong } from "../global/constants";
import { addWishList } from "../Api/api";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/auth";
import { deleteWishlist } from "../Api/api";
import { wishlistRemoved, wishlistAdded } from "../global/constants";
import { useGlobalProvider } from "../global/provider";

const useWishlist = () => {
  const { user } = useAuth();
  const { globalSetSettings } = useGlobalProvider();
  const addToWishlist = useCallback(async (data) => {
    try {
      const body = {
        auth_user_id: user.id,
        products_id: data.products_id,
      };
      await makePostRequest(addWishList, body);
      globalSetSettings((prev) => ({
        ...prev,
        whislistDeleteTrigger: prev.whislistDeleteTrigger + 1,
      }));
      toast.success(wishlistAdded);
    } catch (error) {
      toast.error(somethingWentWrong);
    }
  }, []);

  const removeFromWishlist = useCallback(async (data) => {
    try {
      await makeDeleteRequest(deleteWishlist(user.id, data.products_id));
      globalSetSettings((prev) => ({
        ...prev,
        whislistDeleteTrigger: prev.whislistDeleteTrigger + 1,
      }));
      toast.success(wishlistRemoved);
    } catch (error) {
      toast.error(somethingWentWrong);
    }
  }, []);
  return { addToWishlist, removeFromWishlist };
};

export default useWishlist;
