import React from "react";
import { isValidArrayWithData } from "../../utils/reUsableFun";

const SelectBox = ({
  dataSource = [],
  displayExpr,
  valueExpr,
  onValueChanged,
  placeholder = "Select an option",
  value,
  onFocusOut,
  onChange,
}) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (onValueChanged) {
      onValueChanged(selectedValue);
    }
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (event) => {
    const selectedValue = event.target.value;
    if (onFocusOut) {
      onFocusOut(selectedValue);
    }
  };

  return (
    <select
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      className="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
    >
      <option value="">{placeholder}</option>
      {isValidArrayWithData(dataSource) && dataSource.map((item, index) => (
        <option key={index} value={item[valueExpr]}>
          {item[displayExpr]}
        </option>
      ))}
    </select>
  );
};

export default SelectBox;
