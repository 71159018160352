import React from "react";

const AboutUs = () => {
  return (
    <>
      <section class="relative md:py-24 py-16">
        <div class="container relative">
          <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div class="lg:col-span-5 md:col-span-6">
              <img
                src="assets/images/ab1.jpg"
                class="rounded-t-full shadow-md dark:shadow-gray-800"
                alt=""
              />
            </div>

            <div class="lg:col-span-7 md:col-span-6">
              <div class="lg:ms-8">
                <h6 class="text-orange-500 font-semibold uppercase text-lg">
                  Our Shop
                </h6>
                <h5 class="font-semibold text-3xl leading-normal my-4">
                  Focusing on Quality <br /> Material, Good Design
                </h5>
                <p class="text-slate-400 max-w-xl">
                  Donec non interdum nisl. Sed ut est ac lacus sodales
                  convallis. Nam non velit justo. Mauris vel ultrices tortor.
                  Proin bibendum magna porttitor porttitor suscipit. Praesent
                  sit amet consequat eros. Quisque ullamcorper ornare vulputate.
                  Nam sodales sem id diam sollicitudin, id lobortis tellus
                  tincidunt.
                </p>

                <div class="flex items-center mt-6">
                  <i data-feather="phone" class="w-6 h-6 me-4"></i>
                  <div class="">
                    <h5 class="title font-bold mb-0">Phone</h5>
                    <a
                      href="tel:+152534-468-854"
                      class="tracking-wide text-orange-500"
                    >
                      +152 534-468-854
                    </a>
                  </div>
                </div>

                <div class="flex items-center mt-6">
                  <i data-feather="map-pin" class="w-6 h-6 me-4"></i>
                  <div class="">
                    <h5 class="title font-bold mb-0">Location</h5>
                    <a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      data-type="iframe"
                      class="tracking-wide text-center text-orange-500 lightbox"
                    >
                      View on Google map
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container relative md:mt-24 mt-16">
          <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div class="lg:col-span-5 md:col-span-6 md:order-2 order-1">
              <img
                src="assets/images/ab2.jpg"
                class="rounded-b-full shadow-md dark:shadow-gray-800"
                alt=""
              />
            </div>

            <div class="lg:col-span-7 md:col-span-6 md:order-1 order-2">
              <h6 class="text-orange-500 font-semibold uppercase text-lg">
                Founder
              </h6>
              <h5 class="font-semibold text-3xl leading-normal my-4">
                Maria J. Rose
              </h5>
              <p class="text-slate-400 max-w-xl">
                Donec non interdum nisl. Sed ut est ac lacus sodales convallis.
                Nam non velit justo. Mauris vel ultrices tortor. Proin bibendum
                magna porttitor porttitor suscipit. Praesent sit amet consequat
                eros. Quisque ullamcorper ornare vulputate. Nam sodales sem id
                diam sollicitudin, id lobortis tellus tincidunt.
              </p>

              <ul class="list-none mt-6 space-x-2">
                <li class="inline">
                  <a
                    href="https://dribbble.com/shreethemes"
                    target="_blank"
                    class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"
                  >
                    <i
                      data-feather="dribbble"
                      class="size-5 align-middle"
                      title="dribbble"
                    ></i>
                  </a>
                </li>
                <li class="inline">
                  <a
                    href="http://linkedin.com/company/shreethemes"
                    target="_blank"
                    class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"
                  >
                    <i
                      data-feather="linkedin"
                      class="size-5 align-middle"
                      title="Linkedin"
                    ></i>
                  </a>
                </li>
                <li class="inline">
                  <a
                    href="https://www.facebook.com/shreethemes"
                    target="_blank"
                    class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"
                  >
                    <i
                      data-feather="facebook"
                      class="size-5 align-middle"
                      title="facebook"
                    ></i>
                  </a>
                </li>
                <li class="inline">
                  <a
                    href="https://www.instagram.com/shreethemes/"
                    target="_blank"
                    class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"
                  >
                    <i
                      data-feather="instagram"
                      class="size-5 align-middle"
                      title="instagram"
                    ></i>
                  </a>
                </li>
                <li class="inline">
                  <a
                    href="https://twitter.com/shreethemes"
                    target="_blank"
                    class="inline-flex hover:text-orange-500 dark:hover:text-orange-500"
                  >
                    <i
                      data-feather="twitter"
                      class="size-5 align-middle"
                      title="twitter"
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container relative md:mt-24 mt-16">
          <div class="grid grid-cols-1 justify-center text-center mb-4">
            <h6 class="text-orange-500 font-semibold uppercase text-lg">
              Our Promise
            </h6>
            <h5 class="font-semibold text-3xl leading-normal my-4">
              We Designed and <br /> Developed Products
            </h5>
          </div>

          <div class="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
            <div class="p-6 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-md bg-white dark:bg-slate-900">
              <i class="mdi mdi-truck-check-outline text-4xl text-orange-500"></i>

              <div class="content mt-6">
                <a
                  href=""
                  class="title h5 text-xl font-medium hover:text-orange-500"
                >
                  Free Shipping
                </a>
                <p class="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>

                <div class="mt-4">
                  <a href="" class="text-orange-500">
                    Read More <i class="mdi mdi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="p-6 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-md bg-white dark:bg-slate-900">
              <i class="mdi mdi-account-wrench-outline text-4xl text-orange-500"></i>

              <div class="content mt-6">
                <a
                  href=""
                  class="title h5 text-xl font-medium hover:text-orange-500"
                >
                  24/7 Support
                </a>
                <p class="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>

                <div class="mt-4">
                  <a href="" class="text-orange-500">
                    Read More <i class="mdi mdi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="p-6 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-md bg-white dark:bg-slate-900">
              <i class="mdi mdi-cash-multiple text-4xl text-orange-500"></i>

              <div class="content mt-6">
                <a
                  href=""
                  class="title h5 text-xl font-medium hover:text-orange-500"
                >
                  Payment Process
                </a>
                <p class="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>

                <div class="mt-4">
                  <a href="" class="text-orange-500">
                    Read More <i class="mdi mdi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container relative md:mt-24 mt-16">
          <div class="grid grid-cols-1 justify-center text-center mb-4">
            <h6 class="text-orange-500 font-semibold uppercase text-lg">
              Our Minds
            </h6>
            <h5 class="font-semibold text-3xl leading-normal my-4">
              Meet Our Team Members
            </h5>
          </div>

          <div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div class="lg:col-span-3 md:col-span-6">
              <div class="group text-center">
                <div class="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                  <img src="assets/images/client/04.jpg" class="" alt="" />
                  <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>
                </div>

                <div class="content mt-3">
                  <a
                    href=""
                    class="text-lg font-semibold hover:text-orange-500 duration-500"
                  >
                    Jack John
                  </a>
                  <p class="text-slate-400">Designer</p>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3 md:col-span-6">
              <div class="group text-center">
                <div class="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                  <img src="assets/images/client/16.jpg" class="" alt="" />
                  <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>
                </div>

                <div class="content mt-3">
                  <a
                    href=""
                    class="text-lg font-semibold hover:text-orange-500 duration-500"
                  >
                    Krista John
                  </a>
                  <p class="text-slate-400">Designer</p>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3 md:col-span-6">
              <div class="group text-center">
                <div class="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                  <img src="assets/images/client/06.jpg" class="" alt="" />
                  <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>
                </div>

                <div class="content mt-3">
                  <a
                    href=""
                    class="text-lg font-semibold hover:text-orange-500 duration-500"
                  >
                    Roger Jackson
                  </a>
                  <p class="text-slate-400">Designer</p>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3 md:col-span-6">
              <div class="group text-center">
                <div class="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                  <img src="assets/images/client/07.jpg" class="" alt="" />
                  <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>
                </div>

                <div class="content mt-3">
                  <a
                    href=""
                    class="text-lg font-semibold hover:text-orange-500 duration-500"
                  >
                    Johnny English
                  </a>
                  <p class="text-slate-400">Designer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
