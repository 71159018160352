import React, { useEffect, useState } from "react";
import {
  makeGetRequest,
  makePostRequest,
  makeDeleteRequest,
} from "../../../Api/requestMethods";
import {
  getAccountDetails,
  addAccountDetails,
  updateAccountDetails,
  deleteAccountDetails,
} from "../../../Api/api";
import { MdModeEditOutline } from "react-icons/md";
import { useAuth } from "../../../contexts/auth";
import { useQuery } from "@tanstack/react-query";
import { RiDeleteBinLine } from "react-icons/ri";
import { useGlobalProvider } from "../../../global/provider";
import { onChangeNumberOnly } from "../../../utils/reUsableFun";
import {
  valueOne,
  valueHome,
  valueWork,
  valueZero,
  success,
} from "../../../global/constants";
import { account_details_form } from "../../../schemas/Forms";
import { account_details_validation } from "../../../schemas/Validations";
import { indianStates } from "../../../global/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { somethingWentWrong } from "../../../global/constants";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";
import "./accountDetails.css";

const AddressDetails = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [settings, setSettings] = useState({
    isEditEnabled: false,
    addNewAddressEnabled: false,
    editingIndex: null,
    isEdit: true,
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: account_details_form,
    validationSchema: account_details_validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await handleSubit(values);
      setSubmitting(false);
    },
  });

  const addNewAddress = () => {
    resetForm();
    setSettings((prev) => ({
      ...prev,
      addNewAddressEnabled: !prev.addNewAddressEnabled,
      isEdit: false,
    }));
  };
  const onCancel = () => {
    setSettings((prev) => ({
      ...prev,
      addNewAddressEnabled: !prev.addNewAddressEnabled,
      editingIndex: null,
    }));
    resetForm();
  };

  const fetchAllAccountDetails = async () =>
    await makeGetRequest(getAccountDetails(user?.id));
  const { data: accountAllDetailsData } = useQuery({
    queryKey: ["__allAccountDetails__"],
    queryFn: () => fetchAllAccountDetails(),
  });

  useEffect(() => {
    setFieldValue("auth_user_id", user.id);
  }, [accountAllDetailsData]);

  const handleSubit = async (values) => {
    try {
      const response = await makePostRequest(
        settings.isEdit ? updateAccountDetails : addAccountDetails,
        values
      );
      if (response && response.status === success) {
        queryClient.invalidateQueries({ queryKey: ["__allAccountDetails__"] });
        toast.success(response.message);
        resetForm();
        setSettings((prev) => ({
          ...prev,
          addNewAddressEnabled: !prev.addNewAddressEnabled,
          editingIndex: null,
        }));
      } else {
        toast.error(somethingWentWrong);
      }
    } catch (error) {
      toast.error(somethingWentWrong);
    }
  };

  const editAddress = (data, index) => {
    setSettings((prev) => ({
      ...prev,
      editingIndex: index,
      addNewAddressEnabled: true,
      isEdit: true,
    }));
    setValues(data);
  };

  const deleteAddress = async (data) => {
    try {
      const response = await makeDeleteRequest(
        deleteAccountDetails(data?.account_id)
      );
      if (response && response.status === success) {
        queryClient.invalidateQueries({ queryKey: ["__allAccountDetails__"] });
        toast.success(response.message);
      } else {
        toast.error(somethingWentWrong);
      }
    } catch (error) {
      toast.error(somethingWentWrong);
    }
  };

  return (
    <>
      <>
        <div>
          <div className="d-flex flex-wrap align-baseline">
            <p className="text-2xl">Manage Addresses</p>
          </div>

          <div className="row me-3 cursor-pointer" onClick={addNewAddress}>
            {!settings.addNewAddressEnabled && (
              <div className="w-100 mt-3 d-flex py-2 ms-3 rounded text-white brandColor">
                <IoMdAdd className="mt-1 ms-2" />
                <p className=" ms-3 text-base ">Add Address</p>
              </div>
            )}
          </div>

          {/* Add address */}
          {settings.addNewAddressEnabled && (
            <div>
              <div className="d-flex flex-wrap align-baseline mt-5">
                <p className="text-2xl">Add Address</p>
              </div>
              <div class="row">
                <div class="mt-5 col-lg-4">
                  <label
                    for="name"
                    class="block font-bold text-gray-600 text-xs"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    class="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
                    value={values.name}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "name", value: e.target.value },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "name", value: e.target.value },
                      })
                    }
                  />
                  {touched.name && errors.name ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="mt-5 col-lg-4">
                  <label
                    htmlFor="mobile"
                    className="block font-bold text-gray-600 text-xs"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    placeholder="10-digit mobile number"
                    className="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
                    value={values.mobile}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,10}$/.test(value)) {
                        handleChange({ target: { name: "mobile", value } });
                      }
                    }}
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "mobile", value: e.target.value },
                      })
                    }
                    maxLength="10"
                    pattern="\d*"
                  />
                  {touched.mobile && errors.mobile ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.mobile}
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="row">
                <div class="mt-5 col-lg-4">
                  <label
                    for="name"
                    class="block font-bold text-gray-600 text-xs"
                  >
                    Pin code
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Pin code"
                    class="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
                    value={values.pincode}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,6}$/.test(value)) {
                        handleChange({ target: { name: "pincode", value } });
                      }
                    }}
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "pincode", value: e.target.value },
                      })
                    }
                    maxLength="6"
                    pattern="\d*"
                  />
                  {touched.pincode && errors.pincode ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.pincode}
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="row mt-3 w-100">
                <div class="col-lg-8 pe-0">
                  <label
                    for="name"
                    class="block font-bold text-gray-600 text-xs"
                  >
                    Address
                  </label>
                  <textarea
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Address"
                    class="border border-gray-300 shadow  py-2 ps-2 w-full rounded mb-2 outline-none"
                    value={values.address}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "address", value: e.target.value },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "address", value: e.target.value },
                      })
                    }
                  />
                  {touched.address && errors.address ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.address}
                    </div>
                  ) : null}
                </div>
              </div>

              <div class="row">
                <div className="mt-5 col-lg-4">
                  <label
                    htmlFor="state"
                    className="block font-bold text-gray-600 text-xs "
                  >
                    State
                  </label>
                  <select
                    id="state"
                    name="state"
                    className="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
                    value={values.state}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "state", value: e.target.value },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "state", value: e.target.value },
                      })
                    }
                  >
                    <option value="state0" className="dropText">
                      Select your state
                    </option>
                    {indianStates &&
                      indianStates.map((data, index) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                  </select>
                  {touched.state && errors.state ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.state}
                    </div>
                  ) : null}
                </div>
                <div class="mt-5 col-lg-4">
                  <label
                    for="name"
                    class="block font-bold text-gray-600 text-xs"
                  >
                    City/District
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="City/ District"
                    class="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
                    value={values.city}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "city", value: e.target.value },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "city", value: e.target.value },
                      })
                    }
                  />
                  {touched.city && errors.city ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.city}
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="row">
                <div class="mt-5 col-lg-4">
                  <label
                    for="name"
                    class="block font-bold text-gray-600 text-xs"
                  >
                    Landmark
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Landmark"
                    class="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
                    value={values.landmark}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "landmark", value: e.target.value },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "landmark", value: e.target.value },
                      })
                    }
                  />
                  {touched.landmark && errors.landmark ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.landmark}
                    </div>
                  ) : null}
                </div>

                <div class="mt-5 col-lg-4">
                  <label
                    for="name"
                    class="block font-bold text-gray-600 text-xs"
                  >
                    Alternate Phone (optional)
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Alternate Phone"
                    class="border border-gray-300 shadow p-2 w-full rounded mb-2 outline-none"
                    value={values.alternative_mobile}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (onChangeNumberOnly(value)) {
                        handleChange({
                          target: { name: "alternative_mobile", value },
                        });
                      }
                    }}
                    onBlur={(e) =>
                      handleBlur({
                        target: {
                          name: "alternative_mobile",
                          value: e.target.value,
                        },
                      })
                    }
                    pattern="\d*"
                  />
                </div>

                <div class="row mt-3 w-100">
                  <fieldset
                    value={values.user_name}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "address_type_isHome",
                          value: e.target.value,
                        },
                      })
                    }
                  >
                    <label
                      for="name"
                      class="block font-bold text-gray-600 text-xs mb-1"
                    >
                      Address Type
                    </label>
                    <div className="d-flex">
                      <div class="group me-5">
                        <input
                          type="radio"
                          id="radio01"
                          name="radio"
                          value={valueZero}
                          checked={values.address_type_isHome === valueZero}
                        />
                        <label className="ms-1" for="radio01">
                          Home
                        </label>
                      </div>
                      <div class="group">
                        <input
                          type="radio"
                          id="radio02"
                          name="radio"
                          value={valueOne}
                          checked={values.address_type_isHome === valueOne}
                        />
                        <label className="ms-1" for="radio02">
                          Work
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class=" d-flex align-content-center mb-5">
                <button
                  className="btn brandColorBTN d-flex flex-wrap py-2 px-5 me-4"
                  type="submit"
                >
                  <span className="text-white" onClick={handleSubmit}>
                    Save
                  </span>
                </button>
                <button
                  type="submit"
                  className="btn brandColorBTN d-flex flex-wrap py-2 px-5"
                  onClick={onCancel}
                >
                  <span className="text-white">Cancel</span>
                </button>
              </div>
            </div>
          )}

          <>
            {accountAllDetailsData &&
              accountAllDetailsData.data &&
              accountAllDetailsData.data.map(
                (data, index) =>
                  settings?.editingIndex !== index && (
                    <div
                      className="w-100 border rounded mt-5 ps-2 mb-3"
                      key={index}
                    >
                      <div className="d-flex justify-content-between bg-gray-500	mt-2">
                        <div className="p-2 text-xl font-extrabold">
                          {data?.address_type_isHome === valueOne
                            ? valueWork
                            : valueHome}
                        </div>

                        <div className="d-flex">
                          <div onClick={() => editAddress(data, index)}>
                            <button
                              className="me-4 size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-lg text-center"
                              style={{
                                backgroundColor: "#8cc33f1a",
                                color: "#8cc33f",
                                borderRadius: "9999px",
                                transition: "all 0.5s",
                              }}
                            >
                              <MdModeEditOutline className="icon" />
                            </button>
                          </div>

                          <div onClick={() => deleteAddress(data)}>
                            <button className="me-4 size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 text-red-600  rounded-full">
                              <RiDeleteBinLine className="icon" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="px-2 py-0">
                        <span className="font-semibold">{data?.name}</span> (
                        {data?.mobile})
                      </div>
                      <div className="px-2 py-0 mb-3">
                        {data?.address} - {data?.pincode}
                      </div>
                    </div>
                  )
              )}
          </>
        </div>
      </>
    </>
  );
};

export default AddressDetails;
